import { Box } from "@mui/material";
import { useState, ChangeEvent, useEffect } from "react";
import { Company } from "../../services/models";
import { Services } from "../../Services";
import { Global } from "../../Global";
import ALNModal from "../../components/modal/ALNModal";
import ALNModalHeader from "../../components/modal/ALNModalHeader";
import ALNModalTitle from "../../components/modal/ALNModalTitle";
import ALNINput from "../../components/form/ALNInput/ALNInput";
import ALNSubmitBtn from "../../components/form/ALNBtn/ALNSubmitBtn";
import ALNCancelBtn from "../../components/form/ALNBtn/ALNCancelBtn";
interface CompanyModalProps {
  open: boolean;
  onClose: () => void;
  company?: Company;
}

export function CompanyModal(props: CompanyModalProps): JSX.Element {
  const [company, setCompany] = useState<Company>({});

  useEffect(() => {
    if (props.company) {
      setCompany(props.company);
    } else {
      Services.companyService
        .createCompanyData(Global.getJwtToken()!)
        .then((data) => {
          setCompany(data);
        });
    }
  }, [props.company]);

  const handleClose = () => {
    setCompany({
      companyName: "",
      companyLogoURL: "",
      maxEmailsPerUserPerDay: 0,
    });
    props.onClose();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCompany((prevCompany) => ({
      ...prevCompany,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    Services.companyService.saveCompany(Global.getJwtToken()!, company);
    handleClose();
  };

  return (
    <ALNModal
      open={props.open}
      onClose={handleClose}
    >
      <ALNModalHeader>
        <ALNModalTitle title = {company.companyName !== "" ? "Edit Company" : "New Company"} />
        <ALNCancelBtn onClick = { handleClose } />
      </ALNModalHeader>
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        sx= {{
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <ALNINput
          id="companyName"
          name="companyName"
          label="Company Name"
          placeholder="-"
          value={company.companyName}
          onChange={handleChange}
        />
        <ALNINput
          id="companyLogoURL"
          name="companyLogoURL"
          label="Company Logo URL"
          placeholder="-"
          value={company.companyLogoURL}
          onChange={handleChange}
        />
        
        <ALNINput
          id="maxEmailsPerUserPerDay"
          name="maxEmailsPerUserPerDay"
          label="Max Emails Per User Per Day"
          placeholder="-"
          onChange={handleChange}
          type="number"
          value={company.maxEmailsPerUserPerDay}
        />
        <ALNSubmitBtn />
      </Box>
    </ALNModal>
  );
}
