import React from 'react';
import { Typography } from "@mui/material";
import { ALNColors } from '../../../utils/color';

interface ALNItemProps {
  firstName: string | number | undefined;
  lastName: string | number | undefined;
}

function ALNItem ({ firstName, lastName }: ALNItemProps) {
  return (
    <Typography 
        sx={{
        fontFamily: ['OpenSans', 'sans-serif'],
        fontSize: '1rem',
        fontWeight: 'medium',
        lineHeight: 1.5,
        color: ALNColors.green,
        letterSpacing: 0.0125,
        textTransform: 'capitalize',
        p: 0,
        }}
    > 
        {firstName} {lastName}
    </Typography>
  );
};

export default ALNItem;
