import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { EmailSummary } from "../services/models/EmailSummary";
import { EmailStatus } from "../services/models/EmailStatus";
import { ALNColors } from "../utils/color";
import { ReactComponent as Rounded3dots } from "../assets/images/rounded-3-dots.svg";

interface EmailItemProps {
  data: EmailSummary;
  isSelected: boolean;
}

function EmailItem({ data, isSelected }: EmailItemProps) {
  const [iconSrc, setIconSrc] = useState<string | null>(null);
  const [iconUrl, setIconUrl] = useState<string | null>(
    "email-not-respond-icon.png",
  );
  const [colorVal, setColorVal] = useState<string>(`${ALNColors.yellow}`);
  const [emailStatus, setEmailStatus] = useState<string>("");

  useEffect(() => {
    import(`../assets/images/${iconUrl}`)
      .then((module) => setIconSrc(module.default))
      .catch((err) => console.error("Error loading icon:", err));
  }, [iconUrl]);

  useEffect(() => {
    const emailStatus = data.emailStatus;
    switch (emailStatus) {
      case EmailStatus.AutoProcessed:
        setIconUrl("email-auto-respond-icon.png");
        setColorVal(`${ALNColors.email_auto}`);
        setEmailStatus("Auto");
        break;
      case EmailStatus.ManualProcessed:
        setIconUrl("email-manual-respond-icon.png");
        setColorVal(`${ALNColors.email_manual}`);
        setEmailStatus("Manually");
        break;
      case EmailStatus.NotProcessed:
        setIconUrl("email-not-respond-icon.png");
        setColorVal(`${ALNColors.email_couldnotrespond}`);
        setEmailStatus("Could Not Respond");
        break;
      case EmailStatus.Ignored:
        setIconUrl("email-ignore-icon.png");
        setColorVal(`${ALNColors.email_ignored}`);
        setEmailStatus("Ignored");
        break;
      case EmailStatus.IssueReported:
        setIconUrl("email-annotated-icon.png");
        setColorVal(`${ALNColors.email_withissue}`);
        setEmailStatus("Issue Reported");
        break;
      default:
        setIconUrl("email-not-respond-icon.png");
        setColorVal(`${ALNColors.email_couldnotrespond}`);
        setEmailStatus("Could Not Respond");
    }
  }, [data.emailStatus]);

  return (
    <Box
      sx={{
        display: 'flex',
        height: "80px",
        alignItems: 'center',
        padding: '16px 0px',
        borderRadius: '4px',
        backgroundColor: ALNColors.white,
        marginTop: '2px',
        boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.14)',
        borderLeftWidth: '5px',
        borderLeftColor: ALNColors.yellow,
        justifyContent: 'space-between',
        position: "relative",
      }}
      bgcolor={isSelected ? ALNColors.gray : undefined}
      style={{ borderLeftColor: colorVal }}
    >
      <Box sx={{width: "20%"}}>
        <Typography 
          sx={{
            fontFamily: 'OpenSans',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '21px',
            letterSpacing: 0.02,
            textAlign: 'left',
            padding: "0 0px",
            color: ALNColors.gray_dark
          }}
        >{data.from}</Typography>
      </Box>
      <Box 
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          padding: "0 0px",
          width: '20%',
        }}>
          <Box sx={{
              display: "flex",
              justifyContent: "start",
              gap: "8px",
              width: "fit-content",
              border: "1px solid #000000",
              borderWidth: "1px",
              borderRadius: "100px",
              padding:"5px 12px",
            }}
            style={{ borderColor: colorVal || ALNColors.black }}
          >          
            {iconSrc && 
              <Box
                component="img"
                src={iconSrc}
                alt={data.emailStatus}
              />
            }
            <Typography
              sx={{
                fontFamily: '"OpenSans"',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '21px',
                letterSpacing: '0.02em',
                textAlign: 'left',
                color: ALNColors.yellow,
                textTransform: 'uppercase'
              }}
              style={{ color: colorVal || ALNColors.black }}
            >
              {emailStatus}
            </Typography>
          </Box>
      </Box>
      <Box sx={{width: "40%"}}>
        <Typography 
          sx={{fontFamily: '"OpenSans"',
            fontSize: 16,
            fontWeight: '500',
            lineHeight: '24px',
            padding: "0 0px",
            letterSpacing: 0.02,
            textAlign: 'left',
            color: ALNColors.green_dark
          }}
        >
          {data.subject}
        </Typography>
        <Typography 
          sx={{
            fontFamily: 'OpenSans',
            fontSize: '16px',
            fontWeight: 'normal',
            lineHeight: '24px',
            padding: "0 0px",
            letterSpacing: '0.02em',
            textAlign: 'left',
            color: ALNColors.gray,
          }}
        >
          {data.partialBody}
        </Typography>
      </Box>
      <Box sx={{width: "20%"}}>
        <Typography
          sx = 
          {{
            fontFamily: 'OpenSans',
            fontSize: '14px',
            fontWeight: '500',
            padding: "0 0px",
            lineHeight: '21px',
            letterSpacing: '0.02em',
            color: ALNColors.gray_darker
          }}
        >
          {data.emailDate?.toLocaleString("en-US", {
            day: "2-digit",
            month: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })}
        </Typography>
      </Box>
      <Box sx={{
        position: "absolute",
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
      }}>
        <Rounded3dots/>
      </Box>
    </Box>
  );
}

export default EmailItem;
