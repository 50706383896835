export const ALNColors = {
    primary: "#00a218", // Green
    secondary: "#6c757d", // Gray
    success: "#198754", // Green (success)
    manual: "#ff9d00", // Amber
    error: "#f44336", // Red (error)
    background: "#fbfbff", // Light Gray
    primary_btn: "#4C4E8D", // Indigo
    primary_btn_hover: "#EF6341", // Tomato
    warn: "#FF0000", // Red (warning)
    red: "#ff0000", // Red
    paper: "#fff", // White
    red_bright: "#FF0004", // Bright Red
    red_light: "#f8d7da", // Light Red
    red_dark: "#721c24", // Dark Red
    gray: "#999999", // Medium Gray
    gray_dark: "#b3b3b3", // Dark Gray
    gray_darker: "#666666", // Darker Gray
    gray_very_light: "#e6e6e6", // Very Light Gray
    gray_very_dark: "#4d4d4d", // Very Dark Gray
    black: "#000000", // Black
    black_opacity: "#00000014", // Black (with opacity)
    black_semi_transparent: 'rgba(0, 0, 0, 0.5)', // Semi-transparent Black
    black_light_grayish: "#C1C4C9", // Light Grayish Black
    black_darker_grayish: "#636C78", // Darker Grayish Black
    green: "#051512", // Dark Green
    gray_medium: "#BDBDBD", // Medium Gray (Divider)
    green_dark: "#051512", // Dark Green
    green_darker: "#4F5C59", // Darker Green
    green_lighter: "#00A218", // Lighter Green
    yellow: "#e2cb00", // Yellow
    yellow_dark: "#FF9D00", // Dark Yellow (Amber)
    pink_light: "#f8e7e7", // Light Pink
    indigo: "#3A4188", // Indigo (Mail)
    blue: "#2DC4A9", // Teal
    white: "#ffffff", // White
    white_red: "#f44336", // Red (White-Red)
    white_blue: "#3A2178", // Deep Blue
    white_green: "#F7F8F8", // Off-White
    white_black: "#4caf50", // Green
    white_gray: "#eff2f5", // Light Gray (White-Gray)
    white_less: "#DCDCDC", // Light Gray
    lightgray: "#d3d3d3", // Light Gray
    lightgray_white: "#B3C0BC", // Light Grayish White
    ligthgray_dark: "#333333", // Dark Gray
    primary_input_border: "#DFE7E5", // Light Gray
    ali: "#4D4E8D", // Indigo (Ali)
    email_auto: "#8CB8A2",
    email_couldnotrespond: "#DCCA9F",
    email_manual: "#4D4E8D",
    email_withissue: "#EF6341",
    email_ignored: "#636C78",
    red_very: "#D32F2F",
    background_gray: "#00000033",
}