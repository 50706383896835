import { useEffect, useState } from "react";
import { MailBox } from "../services/models";
import { Services } from "../Services";
import MailboxModal from "./mailbox/MailboxModal";
import { Box, Typography } from "@mui/material";
import ALNBtn from "../components/form/ALNBtn/ALNBtn";
import { MailboxListItem } from "./mailbox/MailboxListItem";
import { Global } from "../Global";
import { ALNColors } from "../utils/color";

export function MailboxList(): JSX.Element {
  const [mailboxes, setMailboxes] = useState<MailBox[]>([]);
  const [editMailbox, setEditMailbox] = useState<MailBox>({});
  const [mailboxModalOpen, setMailboxModalOpen] = useState<boolean>(false);

  const fetchMailboxes = () => {
    Services.mailBoxService
      .getMailBoxes(Global.getJwtToken()!)
      .then((mailboxes: MailBox[]) => {
        setMailboxes(mailboxes);
      });
  };

  const token = Global.getJwtToken();
  useEffect(() => {
    fetchMailboxes();
  }, [token]);

  const handleDeleteMailbox = (emailAddress: string) => {
    Services.mailBoxService
      .deleteMailBox(Global.getJwtToken()!, emailAddress)
      .then(() => {
        setMailboxes(
          mailboxes.filter((mailbox) => mailbox.emailAddress !== emailAddress),
        );
      });
  };

  const handleEditMailbox = (mailbox: MailBox) => {
    setEditMailbox(mailbox);
    setMailboxModalOpen(true);
  };

  const handleMailboxClick = () => {
    Services.mailBoxService
      .createMailboxData(Global.getJwtToken()!)
      .then((mb) => {
        setEditMailbox(mb);
        setMailboxModalOpen(true);
      });
  };

  const handleClose = () => {
    Services.mailBoxService
      .createMailboxData(Global.getJwtToken()!)
      .then((mb) => {
        setEditMailbox(mb);
      });
    setTimeout(() => {
      fetchMailboxes();
    }, 200);
    setMailboxModalOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "25px 50px",
        background: "radial-gradient(83.46% 103.4% at 30.48% 32.62%, rgba(77, 78, 141, 0.15) 0%, rgba(237, 237, 244, 0.15) 100%)"
      }}
    >
      <Typography sx={{fontSize: "10px", lineHeight: "12px", fontWeight: 500, width: "fit-content"}}>
        Mailboxes
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '40px',
          marginBottom: '20px'
        }}
      >
        <Typography sx={{fontSize: "40px", lineHeight: "50px", fontWeight: 400, width: "fit-content"}}>
          Mailboxes
        </Typography>
        <ALNBtn 
          variant="contained"
          sx={{ bgcolor: ALNColors.email_manual, height: 45 }}
          onClick={handleMailboxClick}
          >
          <Typography
            sx={{
              fontSize: '16px !important',
              fontWeight: 600,
              fontFamily: "'Open Sans', sans-serif",
              lineHeight: 1.5,
              color: ALNColors.white,
              letterSpacing: '2%',
              textTransform: 'capitalize',
            }}
          >
            New Mailbox
          </Typography>
        </ALNBtn>
      </Box>
      <Box
        sx={{
          background: ALNColors.white,
          padding: "16px", 
          borderRadius : "16px",
          border: "1px solid "+ALNColors.background_gray,
          flexGrow: 1,
          height: 'calc(100vh - 220px)',
        }}
      >
        <Box sx={{
            overflowY: 'auto',
            maxHeight: '100%'
        }}>
          {mailboxes.map((mailbox: MailBox) => (
            <MailboxListItem
              onDelete={handleDeleteMailbox}
              openEditModal={handleEditMailbox}
              key={mailbox.id}
              mailbox={mailbox}
            />
          ))}
        </Box>
        {mailboxes.length === 0 && (
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            alignContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"300px"}
          >
            <Typography variant="body1" color={ALNColors.secondary}>
              No Mailboxes
            </Typography>
          </Box>
        )}
      </Box>
      <MailboxModal
        open={mailboxModalOpen}
        onClose={() => handleClose()}
        pmailbox={editMailbox}
        isNew={!editMailbox.id}
      />
    </Box>
  );
}
