import { Box } from "@mui/material";
import React from "react";
import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { Global } from "../Global";
import { Services } from "../Services";
import { ALNColors } from "../utils/color";
import ALNBtn from "../components/form/ALNBtn/ALNBtn";

export function Support() {
  const [view, setView] = useState("logs");

  const handleViewChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: string,
  ) => {
    setView(newView);
  };

  let supportService = Services.supportService;

  const [productCounts, setProductCounts] = useState<
    { mailbox: string; count: number }[]
  >([]);
  const [logFileNames, setLogFileNames] = useState<string[]>([]);
  const [logFile, setLogFile] = useState<string>("");
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const [currentLogFile, setCurrentLogFile] = useState<string>("");

  let token = Global.getJwtToken();

  const fetchData = async () => {
    try {
      const productCountsData = await supportService.getProductCount(
        Global.getJwtToken()!,
      );
      setProductCounts(productCountsData);

      const logFileNamesData = await supportService.getLogFileNames(
        Global.getJwtToken()!,
      );
      setLogFileNames(logFileNamesData);

      // const errorMessagesData = await supportService.getErrorMessages(
      //   Global.getJwtToken()!,
      // );

      await supportService.getErrorMessages(
        Global.getJwtToken()!,
      );
      
      setErrorMessages(errorMessages);

      if (currentLogFile) {
        const logFileData = await supportService.getLogFile(
          Global.getJwtToken()!,
          currentLogFile,
        );
        setLogFile(logFileData);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  useEffect(() => {
    const fetchLogFile = async () => {
      try {
        const logFileData = await supportService.getLogFile(
          Global.getJwtToken()!,
          currentLogFile,
        );
        setLogFile(logFileData);
      } catch (error) {
        console.error("Error fetching log file", error);
      }
    };

    if (currentLogFile) {
      fetchLogFile();
    }
  }, [currentLogFile]);

  return (
    <Box
      sx={{ 
        display: "flex", 
        flexDirection: "column",
        padding: "25px 50px",
        background: "radial-gradient(83.46% 103.4% at 30.48% 32.62%, rgba(77, 78, 141, 0.15) 0%, rgba(237, 237, 244, 0.15) 100%)"
      }}
    >
      <Typography sx={{fontSize: "10px", lineHeight: "12px", fontWeight: 500, width: "fit-content"}}>
        Support
      </Typography>
      <Typography sx={{ marginTop: "20px", marginBottom: "20px", fontSize: "40px", lineHeight: "50px", fontWeight: 400, width: "fit-content",}}>
        Support
      </Typography>
      <Box
        sx={{
          background: ALNColors.white,
          padding: "25px 25px", 
          borderRadius : "16px",
          border: "1px solid "+ALNColors.background_gray,
          height: 'calc(100vh - 210px)',
          overflowY: "hidden",
        }}
      >
        <Box sx={{overflow: "hidden"}} display="flex" justifyContent="space-between" alignItems="center">
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={handleViewChange}
            aria-label="view selection"
          >
            <ToggleButton value="logs" aria-label="logs">
              Logs
            </ToggleButton>
            <ToggleButton value="errors" aria-label="errors">
              Errors
            </ToggleButton>
            <ToggleButton value="productCounts" aria-label="product counts">
              Product Counts
            </ToggleButton>
          </ToggleButtonGroup>
          <ALNBtn variant="contained" onClick = { fetchData } 
             sx={{ bgcolor: ALNColors.email_manual, height: 45 }}
          >
            <Typography
              sx={{
                fontSize: '16px !important',
                fontWeight: 400,
                fontFamily: "'Open Sans', sans-serif",
                lineHeight: 1.5,
                color: ALNColors.white,
                letterSpacing: '2%',
                textTransform: 'capitalize',
              }}
            >
              Refresh
            </Typography>
          </ALNBtn>
        </Box>

        {view === "logs" && (
          <Box
            sx={{
              maxHeight: "85%",
              overflowY: "hidden",
              overflowX: "hidden",
            }}
          >
            <h2>Log Files</h2>
            <Box display={"flex"} flexDirection={"row"} width={"100%"}>
              <ul>
                {logFileNames.map((name) => (
                  <li key={name}>
                    <button
                      onClick={() => setCurrentLogFile(name)}
                      style={{
                        color: currentLogFile === name ? "blue" : "inherit",
                      }}
                    >
                      {name}
                    </button>
                  </li>
                ))}
              </ul>

              <Box width={"10px"}></Box>

              <Box
                component="pre"
                sx={{
                  maxHeight: "75vh",
                  overflowY: "auto",
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                }}
              >
                {logFile}
              </Box>
            </Box>
          </Box>
        )}

        {view === "errors" && (
          <Box
            sx={{
              maxHeight: "85%",
              overflowY: "hidden",
              overflowX: "hidden",
            }}
          >
            <h2>Error Messages</h2>
            <ul>
              {errorMessages.map((error) => (
                <li key={error}>{error}</li>
              ))}
              {errorMessages.length === 0 && <li>No errors</li>}
            </ul>
          </Box>
        )}

        {view === "productCounts" && (
          <Box 
            sx={{
              maxHeight: "85%",
              overflowY: "hidden",
              overflowX: "hidden",
            }}
            display="flex" flexDirection="column">
            {productCounts.map((productCount, index) => (
              <Box
                key={productCount.mailbox}
                display="flex"
                justifyContent="space-between"
                width="100%"
                bgcolor={index % 2 === 0 ? "grey.200" : "white"}
                sx={{ marginTop: "15px" }}
              >
                <Typography variant="body1" component="span">
                  {productCount.mailbox}
                </Typography>
                <Typography variant="body1" component="span">
                  {productCount.count}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}
