import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Divider,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { Services } from "../Services";
import { Global, Pages } from "../Global";

import MailIcon from "@mui/icons-material/Mail";
import { DashboardResult } from "../services/models/DashboardResult";
import { TimePeriod } from "../services/models/TimePeriod";
import { MailBox } from "../services/models/MailBox";
import { ALNColors } from "../utils/color";
import {
  NotProcessedReason,
  ProductBreakdown,
  ProductBreakdownSort,
} from "../services/index";
import { SosTwoTone } from "@mui/icons-material";

function Dashboard_Products() {
  const [parseReasons, setParseReasons] = useState<
    { name: string; display: string }[]
  >([]);

  const [dashboardData, setDashboardData] = useState<
    ProductBreakdown[] | undefined
  >();

  const [reason, setReason] = useState<string | undefined>(undefined);

  const [sort, setSort] = useState<ProductBreakdownSort>(
    ProductBreakdownSort.Count,
  );

  const [autoProcessed, setAutoProcessed] = useState<
    "Auto Processed" | "Not Processed" | "All"
  >("All");

  const [timePeriod, setTimePeriod] = useState<TimePeriod>(TimePeriod.Day);

  const [mailbox, setMailbox] = useState<string>(
    Global.getGlobal().CurrentMailbox ?? "",
  );
  const [allMailboxes, setAllMailboxes] = useState<string[]>([]);

  const token = Global.getJwtToken();

  useEffect(() => {
    Services.mailBoxService
      .getMailBoxes(Global.getJwtToken()!)
      .then((mailboxes: MailBox[]) => {
        setAllMailboxes(mailboxes.map((mailbox) => mailbox.emailAddress!));
      });

    Services.dashboardService
      .getParseReasons(Global.getJwtToken()!)
      .then((data) => {
        setParseReasons(data);
      });
  }, [token]);

  useEffect(() => {
    let ap =
      autoProcessed === "All" ? undefined : autoProcessed === "Auto Processed";

    Services.dashboardService
      .getProductBreakdown(
        Global.getJwtToken()!,
        timePeriod,
        sort,
        reason!,
        ap,
        mailbox,
      )
      .then((data) => {
        setDashboardData(data);
      });
  }, [timePeriod, mailbox, sort, reason, autoProcessed]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "25px 50px",
        background:
          "radial-gradient(83.46% 103.4% at 30.48% 32.62%, rgba(77, 78, 141, 0.15) 0%, rgba(237, 237, 244, 0.15) 100%)",
      }}
    >
      <Typography
        sx={{
          fontSize: "10px",
          lineHeight: "12px",
          fontWeight: 500,
          width: "fit-content",
        }}
      >
        Dashboard &gt; DashboardProducts
      </Typography>
      <Typography
        sx={{
          fontSize: "40px",
          lineHeight: "50px",
          fontWeight: 400,
          width: "fit-content",
        }}
      >
        DashboardProducts
      </Typography>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"start"}
        sx={{
          background: ALNColors.white,
          padding: "16px",
          borderRadius: "16px",
          border: "1px solid " + ALNColors.background_gray,
          flexGrow: 1,
          height: "calc(100vh - 200px)",
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box sx={{ minWidth: 200, display: "flex", alignItems: "center" }}>
            <MailIcon
              sx={{
                marginRight: 1,
                marginLeft: 5,
                color: ALNColors.indigo,
                alignItems: "baseline",
              }}
            />
            <FormControl
              variant="standard"
              sx={{ minWidth: 300, color: ALNColors.indigo }}
            >
              <InputLabel id="mailbox-select-label">Mailbox</InputLabel>
              <Select
                labelId="mailbox-select-label"
                id="mailbox-select"
                value={mailbox}
                label="Mailbox"
                onChange={(event) => {
                  Global.getGlobal().CurrentMailbox = event.target.value;
                  setMailbox(event.target.value);
                }}
              >
                {allMailboxes.map((mailbox) => (
                  <MenuItem key={mailbox} value={mailbox}>
                    {mailbox}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <ToggleButtonGroup
            value={timePeriod}
            exclusive
            onChange={(event, newTimePeriod) => {
              if (newTimePeriod !== null) {
                setTimePeriod(newTimePeriod);
              }
            }}
            aria-label="time period"
            sx={{
              minWidth: 500,
              justifyContent: "stretch",
              color: ALNColors.primary,
            }}
          >
            {Object.values(TimePeriod).map((period) => (
              <ToggleButton
                key={period}
                value={period}
                aria-label={period}
                sx={{
                  minWidth: 150,
                  border: "none",
                  "&.Mui-selected": {
                    backgroundColor: ALNColors.indigo,
                    color: ALNColors.paper,
                    "&:hover": {
                      backgroundColor: ALNColors.white_blue,
                    },
                  },
                }}
              >
                {period}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          padding={"10px"}
          marginTop={"10px"}
          borderTop={"1px solid #e0e0e0"}
          borderBottom={"1px solid #e0e0e0"}
        >
          <FormControl variant="standard" sx={{ minWidth: 300 }}>
            <InputLabel id="reason-select-label">Reason</InputLabel>
            <Select
              labelId="reason-select-label"
              id="reason-select"
              value={reason}
              label="Reason"
              onChange={(event) => setReason(event.target.value)}
            >
              <MenuItem key={"all"} value={undefined}>
                All
              </MenuItem>
              {parseReasons.map((reason) => (
                <MenuItem key={reason.name} value={reason.name}>
                  {reason.display}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="standard" sx={{ minWidth: 300 }}>
            <InputLabel id="sort-select-label">Sort By</InputLabel>
            <Select
              labelId="sort-select-label"
              id="sort-select"
              value={sort}
              label="Sort By"
              onChange={(event) =>
                setSort(event.target.value as ProductBreakdownSort)
              }
            >
              {Object.values(ProductBreakdownSort).map((sortOption) => (
                <MenuItem key={sortOption} value={sortOption}>
                  {sortOption}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="standard" sx={{ minWidth: 300 }}>
            <InputLabel id="auto-processed-select-label">
              Email Status
            </InputLabel>
            <Select
              labelId="auto-processed-select-label"
              id="auto-processed-select"
              value={autoProcessed}
              label="Auto Processed"
              // @ts-ignore
              onChange={(event) => setAutoProcessed(event.target.value)}
            >
              <MenuItem value={"Auto Processed"}>Auto Processed</MenuItem>
              <MenuItem value={"Not Processed"}>Not Processed</MenuItem>
              <MenuItem value={"All"}>All</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {dashboardData && (
          <Box
            sx={{
              overflow: "auto",
              maxHeight: "80vh",
            }}
            mt={2}
          >
            <Box
              sx={{
                overflow: "auto",
                maxHeight: "80vh",
              }}
              mt={2}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                p={1}
                bgcolor={ALNColors.gray_very_dark}
                color={ALNColors.paper}
              >
                <Box width="40%">Product</Box>
                <Box
                  width="20%"
                  onClick={() => setSort("Quantity Requested")}
                  sx={{
                    fontWeight:
                      sort === "Quantity Requested" ? "bold" : "normal",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Quantity
                </Box>
                <Box
                  width="20%"
                  onClick={() => setSort("Count")}
                  sx={{
                    fontWeight: sort === "Count" ? "bold" : "normal",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Email Count
                </Box>
                <Box
                  width="20%"
                  onClick={() => setSort("Value")}
                  sx={{
                    fontWeight: sort === "Value" ? "bold" : "normal",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Value
                </Box>
              </Box>
            </Box>
            {dashboardData.map((item, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="space-between"
                p={1}
                bgcolor={index % 2 === 0 ? ALNColors.gray : ALNColors.paper}
              >
                <Box
                  width="40%"
                  onClick={() => {
                    Global.getGlobal().lastSearchString = item.productName;
                    Global.getGlobal().CurrentPage = Pages.EmailList;
                  }}
                >
                  <Box
                    sx={{
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {item.productName}
                  </Box>
                </Box>
                <Box width="20%">
                  {item.totalQtyRequested?.toLocaleString()}
                </Box>
                <Box width="20%">{item.numberOfRequests}</Box>
                <Box width="20%">
                  ${" "}
                  {item.totalValue?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Box>
              </Box>
            ))}

            <Divider></Divider>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Dashboard_Products;
