import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Divider,
  CardContent,
} from "@mui/material";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { Services } from "../Services";
import { Global } from "../Global";

import MailIcon from "@mui/icons-material/Mail";
import SendingEmail from "./../assets/images/sending-email.svg";
import BarChart from "../components/charts/BarChart";
import LineChart from "../components/charts/LineChart";
import GaugeChart from "../components/charts/GaugeChart";
import PieChart from "../components/charts/PieChart";
import { DashboardResult } from "../services/models/DashboardResult";
import { TimePeriod } from "../services/models/TimePeriod";
import { MailBox } from "../services/models/MailBox";
import { ALNColors } from "../utils/color";

function Dashboard() {
  const [dashboardData, setDashboardData] = useState<
    DashboardResult | undefined
  >();
  const [timePeriod, setTimePeriod] = useState<TimePeriod>(TimePeriod.Day);
  const [mailbox, setMailbox] = useState<string>("");
  const [allMailboxes, setAllMailboxes] = useState<string[]>([]);
  const [chartDimensions, setChartDimensions] = useState({
    width: 0,
    height: 0,
  });

  const chartContainerRef = useRef<HTMLDivElement>(null);

  const calculateChartDimensions = useCallback(() => {
    let width = Math.floor((window.innerWidth - 250) * 0.25);
    let height = Math.floor((window.innerHeight - 150) * 0.125);

    setChartDimensions({
      width: width > 300 ? width : 300,
      height: height > 100 ? height : 100,
    });
  }, [window.innerWidth, window.innerHeight]);

  const token = Global.getJwtToken();
  useEffect(() => {
    Services.mailBoxService
      .getMailBoxes(Global.getJwtToken()!)
      .then((mailboxes: MailBox[]) => {
        setAllMailboxes(mailboxes.map((mailbox) => mailbox.emailAddress!));
      });
  }, [token]);

  useEffect(() => {
    Services.dashboardService
      .getDashboardData(Global.getJwtToken()!, timePeriod, mailbox)
      .then((data) => {
        setDashboardData(data);
      });
  }, [timePeriod, mailbox]);

  useEffect(() => {
    calculateChartDimensions(); // Initial calculation
    window.addEventListener("resize", calculateChartDimensions); // Recalculate on window resize
    return () => window.removeEventListener("resize", calculateChartDimensions); // Cleanup listener
  }, [calculateChartDimensions]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "25px 50px",
        background: "radial-gradient(83.46% 103.4% at 30.48% 32.62%, rgba(77, 78, 141, 0.15) 0%, rgba(237, 237, 244, 0.15) 100%)"
      }}
    >
      <Typography sx={{fontSize: "10px", lineHeight: "12px", fontWeight: 500, width: "fit-content"}}>
        Dashboard
      </Typography>
      <Typography sx={{fontSize: "40px", lineHeight: "50px", fontWeight: 400, width: "fit-content"}}>
        Dashboard
      </Typography>
      <Box display={"flex"} flexDirection={"column"} justifyContent={"start"} sx={{
        background: ALNColors.white,
        padding: "16px", 
        borderRadius : "16px",
        border: "1px solid "+ALNColors.background_gray,
        flexGrow: 1,
      }}>
        <Box display="flex" alignItems="center" justifyContent="space-between"
          sx={{
            paddingBottom: "10px",
          }}
        >
          <Box sx={{ minWidth: 200, display: "flex", alignItems: "center" }}>
            <MailIcon
              sx={{
                marginRight: 1,
                marginLeft: 5,
                color: ALNColors.indigo,
                alignItems: "baseline",
              }}
            />
            <FormControl
              variant="standard"
              sx={{ minWidth: 300, color: ALNColors.indigo }}
            >
              <InputLabel id="mailbox-select-label">Mailbox</InputLabel>
              <Select
                labelId="mailbox-select-label"
                id="mailbox-select"
                value={mailbox}
                label="Mailbox"
                onChange={(event) => setMailbox(event.target.value)}
              >
                {allMailboxes.map((mailbox) => (
                  <MenuItem key={mailbox} value={mailbox}>
                    {mailbox}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <ToggleButtonGroup
            value={timePeriod}
            exclusive
            onChange={(event, newTimePeriod) => {
              if (newTimePeriod !== null) {
                setTimePeriod(newTimePeriod);
              }
            }}
            aria-label="time period"
            sx={{
              minWidth: 500,
              justifyContent: "stretch",
              color: ALNColors.primary,
            }}
          >
            {Object.values(TimePeriod).map((period) => (
              <ToggleButton
                key={period}
                value={period}
                aria-label={period}
                sx={{
                  minWidth: 150,
                  border: "none",
                  "&.Mui-selected": {
                    backgroundColor: ALNColors.indigo,
                    color: ALNColors.paper,
                    "&:hover": {
                      backgroundColor: ALNColors.white_blue,
                    },
                  },
                }}
              >
                {period}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>

        <Divider></Divider>

        {dashboardData && (
          <Box display="flex" flexDirection="row" flexWrap={"wrap"}>
            <Box
              display="flex"
              width={"60%"}
              
              flexDirection="column"
              height={chartDimensions.height * 8}
              ref={chartContainerRef}
            >
              <Box flexGrow={1} alignContent={"center"}>
                <BarChart
                  data={dashboardData.emailVolume}
                  timePeriod={timePeriod}
                  numberOfEmails={dashboardData.numberOfEmails ?? 0}
                  width={chartDimensions.width * 1.5}
                  height={chartDimensions.height}
                ></BarChart>
              </Box>
              <Divider></Divider>
              <Box flexGrow={1} alignContent={"center"}>
                <LineChart
                  data={dashboardData.emailVolume}
                  timePeriod={timePeriod}
                  quoteValue={dashboardData.quoteValue ?? 0}
                  width={chartDimensions.width * 1.5}
                  height={chartDimensions.height}
                ></LineChart>
              </Box>
              <Divider></Divider>
              <Box flexGrow={2}>
                <Typography
                  variant="h3"
                  marginTop={4}
                  marginBottom={2}
                  align="center"
                >
                  Processing Time
                </Typography>
                <CardContent
                  sx={{
                    backgroundColor: ALNColors.white_green,
                    padding: "0px",
                  }}
                >
                  <Box display="flex" justifyContent="space-around">
                    <GaugeChart
                      value={dashboardData.autoRespondTime ?? 0}
                      maxValue={10}
                      unit="seconds"
                      color={ALNColors.white_black}
                      width={chartDimensions.width}
                      height={chartDimensions.height * 2}
                      title={"Auto Respond"}
                    />
                    <GaugeChart
                      value={dashboardData.manualRespondTime ?? 0}
                      maxValue={3600}
                      unit="seconds"
                      color={ALNColors.white_red}
                      width={chartDimensions.width}
                      height={chartDimensions.height * 2}
                      title={"Manual Respond"}
                    />
                  </Box>
                  <Divider></Divider>
                  <Typography
                    variant="caption"
                    display={"flex"}
                    letterSpacing={2}
                    sx={{ fontSize: "12px" }}
                    mt={1}
                  >
                    <Box
                      component="img"
                      src={SendingEmail}
                      alt="Sending Email"
                    />
                    <Typography
                      component="b"
                      sx={{
                        color: ALNColors.indigo,
                        paddingTop: "3px",
                        paddingLeft: "3px",
                        paddingRight: "3px",
                      }}
                    >
                      {dashboardData.numberOfEmails?.toLocaleString()}
                    </Typography>
                    <Typography component="span" sx={{ paddingTop: "3px" }}>
                      emails to respond
                    </Typography>
                  </Typography>
                </CardContent>
              </Box>
            </Box>
            <Divider orientation="vertical" flexItem></Divider>
            <Box flex={2}
              width={"39%"}
              >
              <PieChart
                data={dashboardData.quotePercentage}
                width={chartDimensions.width * 1.0}
                height={chartDimensions.height * 4}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;
