import React from "react";
import { ALNColors } from "../../../utils/color";
import { Button, Typography } from "@mui/material";

function ALNSubmitBtn() {
  return(
    <Button
      variant="contained"
      sx={{
        fontFamily: "'OpenSans', sans-serif",
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        color: ALNColors.white,
        letterSpacing: "0.02em",
        margin: "0 0 0 auto",
        backgroundColor: ALNColors.primary_btn,
        borderRadius: '8px',
        padding: "11.5px 48px",
        width: "fit-content",
      }}
      type="submit"
    >
      <Typography
        sx={{  
          fontFamily: "'OpenSans', sans-serif",
          fontSize: "18px",
          fontWeight: 600,
          lineHeight: "27px",
          color: ALNColors.white,
          letterSpacing: "0.02em",
          margin: 0,
          textTransform: "capitalize"
        }}
      >
        Submit
      </Typography>
    </Button>
  )
}

export default ALNSubmitBtn;