import React, { ReactEventHandler } from "react";
import { Box } from "@mui/material";
import { ALNColors } from "../../../utils/color";
interface ALNEmailBtnProps {
  onClick: ReactEventHandler,
  value: string
}
function ALNEmailBtn ({ onClick, value }: ALNEmailBtnProps) {
  return(
    <Box
      marginRight={"10px"}
      sx={{
        borderRadius: '8px',
        padding: '12px 32px',
        bgcolor: ALNColors.primary_btn,
        fontFamily: "OpenSans",
        fontSize: '16px',
        fontWeight: "fontWeightMedium",
        lineHeight: '24px',
        letterSpacing: 0.02,
        textAlign: "left",
        color: ALNColors.white,
        cursor: "pointer"
      }}
      onClick={onClick}
      >
      {value}
    </Box>
  )
}
export default ALNEmailBtn;