import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Divider,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import React, { useEffect, useState } from "react";
import { EmailStatus } from "../services/models/EmailStatus";
import { Email } from "../services/models/Email";
import { ReactComponent as CloseIcon } from "../assets/images/close-icon.svg";
import { Services } from "../Services";
import { User } from "../services/models/User";
import { Global } from "../Global";
import { DisplayEmail } from "../pages/DisplayEmail";
import ParseTool from "../pages/ParseTool";
import ALNInput from "./form/ALNInput/ALNInput";
import { ALNColors } from "../utils/color";
import ALNLabel from "./label/ALNLabel";
import ALNEmailBtn from "./form/ALNBtn/ALNEmailBtn";
import avatarImage from "../assets/images/avatar.png";

interface EmailMainPartProps {
  email: Email;
  onClose: () => void;

  addedTab?: (email: Email) => JSX.Element;
  addedTabTitle?: string;
}

function EmailMainPart(props: EmailMainPartProps) {
  const [iconSrc, setIconSrc] = useState<string | null>(null);
  const [iconUrl, setIconUrl] = useState<string | null>(
    "email-not-respond-icon.png",
  );
  const [colorVal, setColorVal] = useState<string>(`${ALNColors.yellow}`);
  const [emailStatus, setEmailStatus] = useState<string>("");
  const [user, setUser] = useState<User>({});
  const [issue, setIssue] = useState<string>("");

  const [reportIssue, setReportIssue] = useState<boolean>(false);

  const [page, setPage] = useState<"incoming" | "reply" | "details" | "added">(
    "incoming",
  );

  const [openParseTool, setOpenParseTool] = useState<boolean>(false);

  useEffect(() => {
    Services.userService
      .getMe(Global.getJwtToken()!)
      .then((user: any) => {
        setUser(user);
      })
      .catch((e: any) => {
        console.error(e);
      });
  }, []);

  useEffect(() => {
    setPage("incoming");
    setOpenParseTool(false);
  }, [props.email]);

  useEffect(() => {
    import(`../assets/images/${iconUrl}`)
      .then((module) => setIconSrc(module.default))
      .catch((err) => console.error("Error loading icon:", err));
  }, [iconUrl]);

  useEffect(() => {
    const emailStatus = props.email.emailStatus;
    switch (emailStatus) {
      case EmailStatus.AutoProcessed:
        setIconUrl("email-auto-respond-icon.png");
        setColorVal(`${ALNColors.email_auto}`);
        setEmailStatus("Auto");
        break;
      case EmailStatus.ManualProcessed:
        setIconUrl("email-manual-respond-icon.png");
        setColorVal(`${ALNColors.email_manual}`);
        setEmailStatus("Manually");
        break;
      case EmailStatus.NotProcessed:
        setIconUrl("email-not-respond-icon.png");
        setColorVal(`${ALNColors.email_couldnotrespond}`);
        setEmailStatus("Could Not Respond");
        break;
      case EmailStatus.Ignored:
        setIconUrl("email-ignore-icon.png");
        setColorVal(`${ALNColors.email_ignored}`);
        setEmailStatus("Ignored");
        break;
      case EmailStatus.IssueReported:
        setIconUrl("email-annotated-icon.png");
        setColorVal(`${ALNColors.email_withissue}`);
        setEmailStatus("Issue Reported");
        break;
      default:
        setIconUrl("email-not-respond-icon.png");
        setColorVal(`${ALNColors.email_couldnotrespond}`);
        setEmailStatus("Could Not Respond");
    }
  }, [props.email.emailStatus]);

  const handleClose = () => {
    props.onClose();
    setOpenParseTool(false);
  };

  let showTotalValue: boolean =
    props.email.totalValue !== undefined && props.email.totalValue !== 0;
  let showTimeTaken: boolean =
    props.email.timeTakenInSeconds !== undefined &&
    props.email.timeTakenInSeconds !== 0;

  return (
    <Box
      sx={{
        backgroundColor: ALNColors.white,
        padding: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        paddingBottom={"10px"}
        borderBottom={`4px solid ${ALNColors.primary_btn}`}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              flex: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              border: `1px solid lightgray`,
              borderRadius: "5px",
              padding: "5px",
            }}
          >
            {iconSrc && <Avatar alt={props.email.emailStatus} src={iconSrc} />}
            <Typography
              sx={{
                fontFamily: "OpenSans",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "21px",
                letterSpacing: 0.02,
                textAlign: "left",
                color: "#e2cb00",
                textTransform: "uppercase",
              }}
              style={{ color: colorVal || ALNColors.black }}
            >
              {emailStatus}
            </Typography>
          </Box>
          <Box sx={{ margin: "0px 10px" }}>
            <Typography
              sx={{
                fontFamily: "OpenSans",
                fontSize: "12px",
                fontWeight: "normal",
                lineHeight: "18px",
                letterSpacing: 0.02,
                textAlign: "left",
                color: ALNColors.gray,
                "& span": {},
              }}
            >
              From:{" "}
              <Typography
                sx={{
                  fontFamily: "Open Sans, sans-serif",
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "18px",
                  letterSpacing: "0.02px",
                  textAlign: "left",
                  color: ALNColors.gray_darker,
                }}
              >
                {props.email.inboundEmail!.from?.address}
              </Typography>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "'OpenSans', sans-serif",
              fontSize: "14px",
              fontWeight: 450,
              lineHeight: "21px",
              letterSpacing: "0.02em",
              textAlign: "left",
              color: ALNColors.gray,
            }}
          >
            {props.email.emailDate?.toLocaleString()}
          </Typography>
          <CloseIcon
            style={{
              marginLeft: "10px",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
        </Box>
      </Box>

      {showTotalValue && (
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Typography variant="subtitle1" fontWeight={"bold"}>
            Total Value: ${props.email.totalValue!.toLocaleString()}
          </Typography>
          <Typography variant="subtitle1">
            Processed On Server: {props.email.processedOnServer} [
            {props.email.processedOnVersion &&
            props.email.processedOnVersion !== ""
              ? props.email.processedOnVersion
              : "No Version"}
            ]
          </Typography>
        </Box>
      )}

      {showTimeTaken && (
        <Typography variant="subtitle1">
          Time in Seconds: {props.email.timeTakenInSeconds?.toFixed(3)}
        </Typography>
      )}

      {props.email.cannotReplyReason &&
        props.email.cannotReplyReason.length !== 0 && (
          <Box>
            <Divider />
            <ALNLabel color={ALNColors.red}>Cannot Process Reasons:</ALNLabel>
            <ALNLabel
              fontSize={"16px"}
              fontStyle="italic"
              fontWeight={400}
              lineHeight={"24px"}
              color={ALNColors.red}
            >
              <Typography fontSize={"12px"} color={ALNColors.red}>
                {props.email.cannotReplyReason.map(
                  (line: string | number, index) => (
                    <React.Fragment key={index}>
                      - {line}
                      <br />
                    </React.Fragment>
                  ),
                )}
              </Typography>
            </ALNLabel>
          </Box>
        )}

      {props.email.emailStatus === EmailStatus.IssueReported && (
        <Box marginTop={"5px"}>
          <Typography>Reported Issue:</Typography>
          <Box
            sx={{
              fontFamily: "OpenSans",
              fontSize: "16px",
              fontStyle: "italic",
              fontWeight: 400,
              lineHeight: "24px",
              letterSpacing: "0.02em",
              textAlign: "left",
              color: ALNColors.warn,
              border: "1px solid " + ALNColors.warn,
              backgroundColor: ALNColors.pink_light,
            }}
          >
            <Box>
              <Typography color={ALNColors.red}>
                {props.email.issuesNotes}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}

      <Box display="flex" justifyContent="center" paddingTop="10px">
        <ToggleButtonGroup
          value={page}
          exclusive
          onChange={(event, newValue) => {
            setPage(newValue);
          }}
          fullWidth
        >
          <ToggleButton
            sx={{
              minWidth: 150,
              border: "none",
              backgroundColor: ALNColors.lightgray,
              "&.Mui-selected": {
                backgroundColor: ALNColors.indigo,
                color: ALNColors.paper,
                "&:hover": {
                  backgroundColor: ALNColors.white_blue,
                },
              },
            }}
            value="incoming"
          >
            Incoming Mail
          </ToggleButton>
          <ToggleButton
            sx={{
              minWidth: 150,
              border: "none",
              backgroundColor: ALNColors.white_less,
              "&.Mui-selected": {
                backgroundColor: ALNColors.indigo, // Custom selected background color
                color: ALNColors.paper, // White text when selected
                "&:hover": {
                  backgroundColor: ALNColors.white_blue, // Darker blue on hover
                },
              },
            }}
            value="reply"
          >
            Reply Mail
          </ToggleButton>
          <ToggleButton
            sx={{
              minWidth: 150,
              border: "none",
              backgroundColor: ALNColors.white_less,
              "&.Mui-selected": {
                backgroundColor: ALNColors.indigo, // Custom selected background color
                color: "#fff", // White text when selected
                "&:hover": {
                  backgroundColor: ALNColors.white_blue, // Darker blue on hover
                },
              },
            }}
            value="details"
          >
            Details
          </ToggleButton>
          {props.addedTab && (
            <ToggleButton
              sx={{
                minWidth: 150,
                border: "none",
                backgroundColor: ALNColors.white_less,
                marginLeft: "2px",
                "&.Mui-selected": {
                  backgroundColor: ALNColors.indigo, // Custom selected background color
                  color: ALNColors.paper, // White text when selected
                  "&:hover": {
                    backgroundColor: ALNColors.white_blue, // Darker blue on hover
                  },
                },
              }}
              value="added"
            >
              {props.addedTabTitle ?? "Extra"}
            </ToggleButton>
          )}
        </ToggleButtonGroup>
      </Box>
      {page === "incoming" && (
        <Box height={"500px"} overflow={"auto"} paddingTop={"10px"}>
          <DisplayEmail email={props.email.inboundEmail!} isReply={false} />
        </Box>
      )}

      {page === "reply" && (
        <Box height={"500px"} overflow={"auto"} paddingTop={"10px"}>
          <DisplayEmail email={props.email.reply!} isReply={true} />
        </Box>
      )}

      {page === "details" && (
        <Box>
          {props.email.debugInfo && (
            <Box
              marginTop={"10px"}
              border={`1px solid ${ALNColors.gray}`}
              padding={"10px"}
              height={"500px"}
              overflow={"auto"}
            >
              <Typography variant="subtitle1" fontWeight={"bold"}>
                Details:
              </Typography>
              <Divider />
              <Typography
                component="pre"
                sx={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
              >
                {JSON.stringify(props.email.debugInfo, null, 2)}
              </Typography>
            </Box>
          )}
        </Box>
      )}

      {props.addedTab && page === "added" && (
        <Box>{props.addedTab!(props.email)}</Box>
      )}

      {!reportIssue && (
        <Box display={"flex"} paddingTop={"10px"} justifyContent={"center"}>
          <ALNEmailBtn
            value="Block User"
            onClick={() => {
              Services.emailAddressRuleService.blockUser(
                Global.getJwtToken()!,
                props.email.inboundEmail!.from!.address!,
              );
              props.onClose();
            }}
          />
          {props.email.emailStatus !== EmailStatus.Ignored && (
            <ALNEmailBtn
              onClick={() => {
                Services.emailService.ignoreEmail(
                  Global.getJwtToken()!,
                  Global.getGlobal().CurrentMailbox!,
                  props.email.id,
                );
                props.onClose();
              }}
              value="Ignore Email"
            />
          )}

          {props.email.emailStatus === EmailStatus.IssueReported && (
            <ALNEmailBtn
              value="Mark Resolved"
              onClick={() => {
                Services.emailService.clearIssue(
                  Global.getJwtToken()!,
                  Global.getGlobal().CurrentMailbox!,
                  props.email.id,
                );
                props.onClose();
              }}
            />
          )}

          {props.email.emailStatus !== EmailStatus.IssueReported && (
            <ALNEmailBtn
              onClick={() => {
                setReportIssue(true);
              }}
              value="Report Issue"
            />
          )}

          <Box
            sx={{
              borderRadius: "8px",
              padding: "12px 32px",
              bgcolor: ALNColors.primary_btn,
              fontFamily: "OpenSans",
              fontSize: "16px",
              fontWeight: "fontWeightMedium",
              lineHeight: "24px",
              letterSpacing: 0.02,
              textAlign: "left",
              color: ALNColors.white,
              cursor: "pointer",
            }}
            marginLeft={"10px"}
            onClick={() => {
              setOpenParseTool(true);
            }}
          >
            Open Parse Tool
          </Box>
        </Box>
      )}

      {reportIssue && (
        <Box paddingTop={"10px"}>
          <ALNInput
            type="multiline"
            rows={4}
            value={issue}
            onChange={(e) => setIssue(e.target.value)}
          />
          <Box paddingTop={"10px"} display={"flex"} justifyContent={"right"}>
            <ALNEmailBtn
              onClick={() => {
                Services.emailService.reportIssue(
                  Global.getJwtToken()!,
                  Global.getGlobal().CurrentMailbox!,
                  props.email.id,
                  issue,
                );
                setIssue("");
                setReportIssue(false);
                props.onClose();
              }}
              value="Report"
            />
            <ALNEmailBtn
              onClick={() => {
                setIssue("");
                setReportIssue(false);
              }}
              value="Cancel"
            />
          </Box>
        </Box>
      )}

      {openParseTool && (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={1300}
          sx={{ backgroundColor: ALNColors.black_semi_transparent }}
        >
          <Box
            padding="20px"
            borderRadius="8px"
            boxShadow={3}
            width={"80%"}
            height={"80%"}
            sx={{ backgroundColor: ALNColors.white }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" gutterBottom>
                Parse Tool
              </Typography>
              <CloseIcon
                style={{
                  marginLeft: "10px",
                  cursor: "pointer",
                }}
                onClick={() => setOpenParseTool(false)}
              />
            </Box>
            <Box>
              <ParseTool emailData={props.email.inboundEmail!} />
            </Box>
            <Box display="flex" justifyContent="flex-end" marginTop="20px">
              <Box
                sx={{
                  borderRadius: "8px",
                  padding: "12px 32px",
                  bgcolor: ALNColors.primary_btn,
                  fontFamily: "OpenSans",
                  fontSize: "16px",
                  fontWeight: "fontWeightMedium",
                  lineHeight: "24px",
                  letterSpacing: 0.02,
                  textAlign: "left",
                  color: ALNColors.white,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenParseTool(false);
                }}
              >
                Close
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default EmailMainPart;
