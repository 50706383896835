import React from 'react';
import { ToggleButtonGroup, ToggleButton } from '@mui/material'; 

interface ALNToggleThreeBtnProps {
  value?: string;
  firstToggle?: string | undefined;
  secondToggle?: string | undefined;
  thirdToggle?: string | undefined;
  aria_label?: string | undefined;
  aria_label_first?: string | undefined;
  aria_label_second?: string | undefined;
  aria_label_third?: string | undefined;
  sx?:object | undefined;
  onChange: (value: string) => void;
}

function ALNToggleThreeBtn ({ 
  value,
  firstToggle="", 
  secondToggle="",
  thirdToggle="",
  aria_label,
  aria_label_first,
  aria_label_second,
  aria_label_third,
  sx,
  onChange 
}: ALNToggleThreeBtnProps) {
  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      sx={sx}
      aria-label={aria_label}
      onChange={(event: any, newValue: any) => {
        if (newValue !== null) {
          onChange(newValue);
        }
      }}
    >
      <ToggleButton
        aria-label={aria_label_first}
        value={firstToggle}
      >
        {firstToggle.toUpperCase()}
      </ToggleButton>
      <ToggleButton
        aria-label={aria_label_second}
        value={secondToggle}
      >
        {secondToggle.toUpperCase()}
      </ToggleButton>
      <ToggleButton
        aria-label={aria_label_third}
        value={thirdToggle}
      >
        {thirdToggle.toUpperCase()}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ALNToggleThreeBtn;
