import React from "react";
import { Email } from "../../../services/models/Email";
import { Box, Typography } from "@mui/material";
import EmailMainPart from "../../../components/EmailMainPart";
import { ALNColors } from "../../../utils/color";
interface IssueReportedProps {
  emailData: Email;
  onClose: () => void;
}

function IssueReported(props: IssueReportedProps) {
  return (
    <Box
      sx={{
        borderRadius: 2,
        boxShadow: `0px 1px 1px 0px ${ALNColors.black_opacity}`,
        backgroundColor: ALNColors.white,
        flex: 1,
      }}
    >
      <EmailMainPart
        email={props.emailData}
        onClose={props.onClose}
        addedTabTitle="Resolve"
        addedTab={() => {
          return (
            <Box 
              sx={{padding: '12px'}}
            >
              <Typography 
                sx={{
                  fontFamily: "'OpenSans'",
                  fontSize: "18px",
                  fontWeight: 600,
                  lineHeight: "27px",
                  letterSpacing: "0.02em",
                  textAlign: "left",
                  color: ALNColors.gray_darker
                }}
              >Notes</Typography>

              {props.emailData.annotations &&
                props.emailData.annotations.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <Box mb={2}>
                    </Box>
                  </React.Fragment>
                ))}
            </Box>
          );
        }}
      />
    </Box>
  );
}

export default IssueReported;
