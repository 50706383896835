import {
  Box,
  MenuItem,
  Typography,
} from "@mui/material";
import { User } from "../../services/models";
import { useEffect, useState } from "react";
import { Services } from "../../Services";
import { Global } from "../../Global";
import ALNModal from "../../components/modal/ALNModal";
import ALNModalBody from "../../components/modal/ALNModalBody";
import ALNModalHeader from "../../components/modal/ALNModalHeader";
import { ALNColors } from "../../utils/color";
import ALNInput from "../../components/form/ALNInput/ALNInput";
import ALNBtn from "../../components/form/ALNBtn/ALNBtn";

type UpdateUserModalProps = {
  open: boolean;
  onClose: () => void;
  user: User;
  title: string;
  currentlyChanged: "password" | "company";
};

export function UpdateUserModal(props: UpdateUserModalProps) {
  const [password, setPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [company, setCompany] = useState<string>();
  const [companies, setCompanies] = useState<string[] | undefined>([]);

  let token = Global.getJwtToken();

  useEffect(() => {
    setCompany(Global.getGlobal().company);
  }, [token]);

  useEffect(() => {
    Global.getAllCompanies().then((companies) => {
      setCompanies(companies);
    });
  }, [props.user]);

  const updateUserCompany = () => {
    if (props.user.emailAddress && props.user.id && company) {
      Services.userService
        .changeCompany(Global.getJwtToken()!, company)
        .then((t) => {
          Global.setJwtToken(t);
          props.onClose();
        });
    }
  };

  const updateUserPassword = () => {
    if (props.user.emailAddress && props.user.id) {
      Services.userService
        .changePassword(props.user.emailAddress, password, newPassword)
        .then(() => {
          props.onClose();
        });
    }
  };

  return (
    <ALNModal
      open={props.open}
      onClose={props.onClose}
    >
      <ALNModalHeader>
        <Typography
          sx={{
            fontFamily: 'OpenSans, sans-serif',
            fontSize: 3,
            fontWeight: 550,
            lineheight: 1,
            color: ALNColors.white,
            letterspacing: 0.02,
            margin: 0,
            display: 'inline-block'
          }}
          component="h2"
        >
          {props.title}
        </Typography>
        <Typography
          sx={{
            fontFamily: 'OpenSans, sans-serif',
            fontSize: '18px',
            fontWeight: 500,
            lineHeight: 1.5,
            color: ALNColors.white,
            letterSpacing: 0.02,
            margin: 0,
            display: 'inline-block',
            width: 'initial'
          }}
        >
          {props.user.firstName} {props.user.lastName}
        </Typography>
      </ALNModalHeader>
      <ALNModalBody>
        {props.currentlyChanged === "password" && (
          <Box>
            <ALNInput 
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
              label="Password"
              type="password"
            />
            <ALNInput 
              value={newPassword}
              onChange={(e: any) => setNewPassword(e.target.value)}
              label="New Password"
              type="password"
            />
          </Box>
        )}

        {props.currentlyChanged === "company" && (
          <ALNInput 
            type="select"
            label="Company"
            required={true}
            id="company"
            name="company"
            value={company || ""}
            onChange={(e: any) => setCompany(e.target.value)}
          >
            {(companies ?? []).map((company) => (
              <MenuItem key={company} value={company}>
                {company}
              </MenuItem>
            ))}
          </ALNInput>
        )}
        <ALNBtn
          variant="contained"
          sx={{
            fontFamily: 'OpenSans, sans-serif',
            fontSize: 2,
            fontWeight: 600,
            lineHeight: 3,
            color: ALNColors.primary,
            letterSpacing: 0.02,
            margin: '0 0 0 auto',
            backgroundColor: ALNColors.primary_btn,
            borderRadius: 1,
            padding: '11.5px 48px',
            width: 'fit-content'
          }}
          onClick={ () => {
            props.currentlyChanged === "password"
              ? updateUserPassword()
              : updateUserCompany()
          }}
        >
          <Typography 
            sx={{
              fontFamily: 'OpenSans, sans-serif',
              fontSize: '18px',
              fontWeight: 600,
              lineHeight: '27px',
              color: ALNColors.white,
              letterSpacing: 0.02,
              margin: 0,
              textTransform: 'capitalize',
            }}
          >
            Update User 
          </Typography>
        </ALNBtn>
      </ALNModalBody>
    </ALNModal>
  );
}
