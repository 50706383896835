// src/components/Charts/GaugeChart.tsx
import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

interface GaugeChartProps {
    value: number;
    maxValue: number;
    unit: string;
    color: string;
    width: number;
    height: number;
    title: string;
}

function GaugeChart({ value, maxValue, color, width, height, unit, title}: GaugeChartProps) {
    const ref = useRef<SVGSVGElement | null>(null);

    useEffect(() => {
        const svg = d3.select(ref.current);
        const radius = Math.min(width, height) / 2;

        const backgroundArc = d3.arc()
            .innerRadius(radius + 10)
            .outerRadius(radius + 12)
            .startAngle(-Math.PI / 2)
            .endAngle(Math.PI / 2);

        const valueArc = d3.arc()
            .innerRadius(radius - 5)
            .outerRadius(radius + 5)
            .startAngle(-Math.PI / 2);

        const areaArc = d3.arc()
            .innerRadius(radius + 5)
            .outerRadius(radius - 25)
            .startAngle(-Math.PI / 2);

        svg.attr('width', width).attr('height', height);
        svg.selectAll('*').remove();

        const g = svg.append('g').attr('transform', `translate(${width / 2}, ${height - 30})`);

        // Background Arc
        g.append('path')
            .attr('d', backgroundArc as any)
            .style('fill', '#E0E2E4');

        // Animated Area Arc with lighter color
        const lighterColor = lightenHexColor(color, 80);
        const areaPath = g.append('path')
            .datum({ endAngle: -Math.PI / 2 })
            .style('fill', lighterColor)
            .attr('d', areaArc as any);

        areaPath.transition()
            .delay(300) // Slight delay to stagger animation
            .duration(1500)
            .attrTween('d', (d) => {
                const interpolate = d3.interpolate(-Math.PI / 2, -Math.PI / 2 + (value / maxValue) * Math.PI);
                return (t) => areaArc({ ...d, endAngle: interpolate(t) } as any)!;
            });

        // Create Animated Value Arc
        const valuePath = g.append('path')
            .datum({ endAngle: -Math.PI / 2 }) // Start with zero angle
            .style('fill', color)
            .attr('d', valueArc as any);

        // Animate the value arc
        valuePath.transition()
            .duration(1500) // 1.5 seconds animation
            .attrTween('d', (d) => {
                const interpolate = d3.interpolate(-Math.PI / 2, -Math.PI / 2 + (value / maxValue) * Math.PI);
                return (t) => valueArc({ ...d, endAngle: interpolate(t) } as any)!;
            });

        // Title
        g.append('text')
            .attr('text-anchor', 'middle')
            .attr('font-size', '16px')
            .attr('y', -radius - 40)
            .text(title);

        let showValue = title === "Auto Respond"? value.toFixed(1): value.toFixed(1).toLocaleString();
        // Value Text with Animation
        g.append('text')
            .attr('text-anchor', 'middle')
            .attr('y', 0)
            .attr('font-size', '32px')
            .attr('fill', '#3A4188')
            .text(showValue);

        if (value > 360) {
            g.append('text')
                .attr('text-anchor', 'middle')
                .attr('y', -radius + 60)
                .attr('font-size', '16px')
                .attr('fill', color)
                .text(`${(value / 3600).toFixed(1).toLocaleString()} h`);
        }

        // Unit Text
        g.append('text')
            .attr('text-anchor', 'middle')
            .attr('y', 30)
            .attr('font-size', '16px')
            .attr('fill', color)
            .text(unit);

        // Left and Right Labels
        g.append('text')
            .attr('text-anchor', 'middle')
            .attr('x', -radius - 20)
            .attr('font-size', '12px')
            .attr('fill', color)
            .text('0');

        g.append('text')
            .attr('text-anchor', 'middle')
            .attr('x', radius + 20)
            .attr('font-size', '16px')
            .attr('fill', color)
            .text('∞');

    }, [value, maxValue, color, width, height, title, unit]);

    // Utility function to lighten a hex color
    const lightenHexColor = (hex: string, percent: number) => {
        const rgb = hexToRgb(hex);
        const lighterRgb = lightenRgb(rgb, percent);
        return rgbToHex(lighterRgb.r, lighterRgb.g, lighterRgb.b);
    };

    const hexToRgb = (hex: string) => {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return { r, g, b };
    };

    const lightenRgb = ({ r, g, b }: { r: number; g: number; b: number }, percent: number) => {
        const newR = Math.min(255, Math.floor(r + (255 - r) * (percent / 100)));
        const newG = Math.min(255, Math.floor(g + (255 - g) * (percent / 100)));
        const newB = Math.min(255, Math.floor(b + (255 - b) * (percent / 100)));
        return { r: newR, g: newG, b: newB };
    };

    const rgbToHex = (r: number, g: number, b: number) => {
        return (
            '#' + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toUpperCase()
        );
    };

    return <svg ref={ref} />;
};

export default GaugeChart;
