import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import ALNBtn from "../components/form/ALNBtn/ALNBtn";

import { ALNColors } from "../utils/color";
import { User } from "../services/models";
import { Services } from "../Services";
import { UserListItem } from "./user/UserListItem";
import { NewUserModal } from "./user/NewUserModal";
import { Global } from "../Global";

export function UsersList(): JSX.Element {
  const [userList, setUserList] = useState<User[]>([]);
  const [userModalOpen, setUserModalOpen] = useState<boolean>(false);
  const [editUser, setEditUser] = useState<User>({});
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const fetchUsers = () => {
    Services.userService
      .getUsers(Global.getJwtToken()!, false)
      .then((users: User[]) => {
        setUserList(users);
      })
      .catch((err) => {
        console.log(err)
      })
  };

  const token = Global.getJwtToken();

  useEffect(() => {
    fetchUsers();
  }, [token]);

  const handleUserClick = () => {
    setIsEdit(false);
    setUserModalOpen(true);
  };

  const handleUserModalClose = () => {
    setUserModalOpen(false);
    setEditUser({});
    setTimeout(() => {
      fetchUsers();
    }, 200);
    Global.resetUser();
  };

  const handleEditUser = (user: User) => {
    setEditUser((prevUser) => ({ ...prevUser, ...user }));
    setIsEdit(true);
    setUserModalOpen(true);
  };

  const handleDeleteUser = (user: User) => {
    Services.userService
      .deleteUser(Global.getJwtToken()!, user.emailAddress!)
      .then(() => {
        fetchUsers();
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        overflowY: "hidden",
        gap: "20px",
        padding: "25px 50px",
        background: "radial-gradient(83.46% 103.4% at 30.48% 32.62%, rgba(77, 78, 141, 0.15) 0%, rgba(237, 237, 244, 0.15) 100%)"
      }}
    >
      <Typography sx={{fontSize: "10px", lineHeight: "12px", fontWeight: 500, width: "fit-content"}}>
        User List
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '40px',
          marginBottom: '20px',
        }}
      >
        <Typography sx={{fontSize: "40px", lineHeight: "50px", fontWeight: 400, width: "fit-content"}}>
          User List
        </Typography>
        <ALNBtn 
          variant="contained"
          sx={{ bgcolor: ALNColors.email_manual, height: 45 }}
          onClick={handleUserClick}
          >
          <Typography
            sx={{
              fontSize: '16px !important',
              fontWeight: 600,
              fontFamily: "'Open Sans', sans-serif",
              lineHeight: 1.5,
              color: ALNColors.white,
              letterSpacing: '2%',
              textTransform: 'capitalize',
            }}
          >
            New User
          </Typography>
        </ALNBtn>
      </Box>
      <Box
        sx={{
          background: ALNColors.white,
          padding: "16px", 
          borderRadius : "16px",
          border: "1px solid "+ALNColors.background_gray,
          flexGrow: 1,
          height: 'calc(100vh - 220px)',
        }}
      >
        <Box
          sx={{
            maxHeight: '100%',
            overflowY: 'scroll'
          }}
        >
          {userList.map((user: User) => (
            <UserListItem
              user={user}
              key={user.id}
              onDelete={handleDeleteUser}
              openEditModal={handleEditUser}
            />
          ))}
        </Box>
      </Box>
      <NewUserModal
        isEdit={isEdit}
        open={userModalOpen}
        onClose={handleUserModalClose}
        user={editUser}
      />
    </Box>
  );
}
