import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  TextField,
  Typography,
  InputAdornment,
  Menu,
  MenuItem,
} from "@mui/material";
import EmailState from "../components/EmailState";
import EmailItem from "../components/EmailItem";
import { Services } from "../Services";
import { EmailStatus } from "../services/models/EmailStatus";
import { EmailSummary } from "../services/models/EmailSummary";
import { Global } from "../Global";
import { Email } from "../services/models";
import EmailEditLayer from "./email/layers/EmailEditLayer";
import { ALNColors } from "../utils/color";
import ALNBtn from "../components/form/ALNBtn/ALNBtn";
import { ReactComponent as DownIcon } from "../assets/images/down-icon.svg";
import { ReactComponent as SearchIconInBlack } from "../assets/images/searchIconInBlack.svg";

function EmailList(props: { mailboxName: string }): JSX.Element {
  const token = Global.getJwtToken()!;
  const EmailStatusArray = Object.values(EmailStatus);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleEmailStatus = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const statusConfig = {
    AutoProcessed: {
      displaytitle: "Auto",
      icon: "email-auto-respond-icon.png",
      color: ALNColors.email_auto,
    },
    ManualProcessed: {
      displaytitle: "Manual",
      icon: "email-manual-respond-icon.png",
      color: ALNColors.email_manual,
    },
    NotProcessed: {
      displaytitle: "Could Not Respond",
      icon: "email-not-respond-icon.png",
      color: ALNColors.email_couldnotrespond,
    },
    Ignored: {
      displaytitle: "Ignored",
      icon: "email-ignore-icon.png",
      color: ALNColors.email_ignored,
    },
    IssueReported: {
      displaytitle: "With Issues",
      icon: "email-annotated-icon.png",
      color: ALNColors.email_withissue,
    },
    All: {
      displaytitle: "All E-Mails",
      icon: "",
      color: ALNColors.blue,
    },
  };

  const mailboxName = props.mailboxName;

  const emailStates = EmailStatusArray.map((status, index) => ({
    id: index + 1,
    status: status,
    title: status,
    displaytitle: statusConfig[status].displaytitle,
    icon: statusConfig[status].icon,
    color: statusConfig[status].color,
  }));

  const [page, setPage] = useState<number>(1);
  const pageSize = 10;

  const [filter, setFilter] = useState<EmailStatus>(EmailStatus.All);

  const [emailToEdit, setEmailToEdit] = React.useState<Email | null>(null);
  const [lastEmailToEdit, setLastEmailToEdit] = React.useState<Email | null>(
    null,
  );

  const [filteredEmailList, setFilteredEmailList] = React.useState<
    EmailSummary[]
  >([]);

  const [searchValue, setSearchValue] = React.useState<string>(
    Global.getGlobal().lastSearchString ?? "",
  );

  useEffect(() => {
    Global.getGlobal().lastSearchString = undefined;
  });

  useEffect(() => {
    if (mailboxName === undefined) {
      setFilteredEmailList([]);
    } else {
      fetchEmails(filter, searchValue, page);
    }
  }, [searchValue, token, mailboxName, page]);

  const handleManualFilter = (status: EmailStatus) => {
    setFilter(status);
    fetchEmails(status, searchValue, page);
    setPage(1);
  };

  const handleClickItem = (data: EmailSummary) => {
    Services.emailService
      .getEmail(token, mailboxName!, data.id!)
      .then((email: Email) => {
        setEmailToEdit(email);
        setLastEmailToEdit(email);
      });
  };

  function fetchEmails(
    emailStatus: EmailStatus,
    searchValue: string,
    pg: number,
  ) {
    let beginDate: Date | undefined = undefined;
    let endDate: Date | undefined = undefined;

    if (searchValue !== "") {
      Services.emailService
        .searchEmails(
          Global.getJwtToken()!,
          mailboxName,
          searchValue,
          emailStatus,
          beginDate,
          endDate,
          pg,
          pageSize,
        )
        .then((emails: any) => {
          setFilteredEmailList(emails);
        })
        .catch((e: any) => {
          console.error(e);
        });
    } else {
      Services.emailService
        .getEmailsFiltered(
          Global.getJwtToken()!,
          mailboxName,
          emailStatus,
          beginDate,
          endDate,
          pg,
          pageSize,
        )
        .then((emails: any) => {
          setFilteredEmailList(emails);
        })
        .catch((e: any) => {
          console.error(e);
        });
    }
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSearchValue(event.target.value);
    setPage(1);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "25px 50px",
        background:
          "radial-gradient(83.46% 103.4% at 30.48% 32.62%, rgba(77, 78, 141, 0.15) 0%, rgba(237, 237, 244, 0.15) 100%)",
      }}
    >
      <Typography
        sx={{
          fontSize: "10px",
          lineHeight: "12px",
          fontWeight: 500,
          width: "fit-content",
        }}
      >
        Email &gt; replyaitest.dev
      </Typography>
      <Typography
        sx={{
          fontSize: "40px",
          lineHeight: "50px",
          fontWeight: 400,
          width: "fit-content",
        }}
      >
        {Global.getGlobal().CurrentMailbox ?? "No Mailbox Selected"}
      </Typography>
      <Box display={"flex"} justifyContent="space-between">
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <FormControl
            sx={{
              minWidth: 300,
              width: "35vw",
              border: "1px solid " + ALNColors.background_gray,
              borderRadius: "100px",
            }}
          >
            <TextField
              id="search-listing"
              label=""
              placeholder="Search List By Keyword"
              variant="standard"
              onChange={handleSearch}
              value={searchValue}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIconInBlack />
                      {/* <SearchIcon style={{ color: ALNColors.gray }} /> */}
                    </InputAdornment>
                  ),
                },
              }}
              sx={{
                color: ALNColors.gray,
                height: "45px",
                "& .MuiInputBase-root": {
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "21px",
                  letterSpacing: "0.02em",
                  textAlign: "left",
                  color: ALNColors.gray,
                  height: "100%",
                  border: "1px solid " + ALNColors.primary_input_border,
                  boxShadow: "0px 1px 1px 0px #00000014",
                  borderRadius: "100px",
                  backgroundColor: ALNColors.white,
                  padding: "0 12px",
                },
                "& .MuiInputBase-root::before": {
                  border: "none !important",
                },
                "& .MuiInputBase-root::after": {
                  border: "none",
                },
                '& input[type="text"]': {
                  border: "none",
                  paddingLeft: "0",
                },
              }}
            />
          </FormControl>
          <Box
            onClick={() => setSearchValue("")}
            paddingLeft={"5px"}
            sx={{ display: "none" }}
          >
            X
          </Box>
        </Box>
        <Box
          sx={{
            display: "none",
            // display: "flex",
            alignItems: "center",
            paddingTop: "8px",
            paddingBottom: "8px",
            borderRadius: "8px",
            backgroundColor: ALNColors.white,
            boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.08)",
            gap: "8px",
          }}
        >
          {emailStates.map((item) => (
            <EmailState
              manualFilter={handleManualFilter}
              activeStatus={filter}
              key={item.id}
              data={item}
            />
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "100px",
              background: ALNColors.white,
              border: "1px solid " + ALNColors.background_gray,
              padding: "9px 12px",
              cursor: "pointer",
            }}
            onClick={handleEmailStatus}
          >
            <Typography>All Status</Typography>
            <DownIcon />
          </Box>
          <ALNBtn
            variant="contained"
            sx={{ bgcolor: ALNColors.email_manual, height: 45 }}
            onClick={() => {
              Services.emailService.checkEmail(
                Global.getJwtToken()!,
                Global.getGlobal().CurrentMailbox,
              );
            }}
          >
            <Typography
              sx={{
                fontSize: "16px !important",
                fontWeight: 350,
                fontFamily: "'Open Sans', sans-serif",
                lineHeight: 1.5,
                color: ALNColors.white,
                letterSpacing: "2%",
                textTransform: "capitalize",
                padding: "0px",
              }}
            >
              Check Now +
            </Typography>
          </ALNBtn>
        </Box>
      </Box>
      {/* mails table */}
      <Box
        sx={{
          background: ALNColors.white,
          borderRadius: "16px",
          padding: "24px",
          border: "1px solid " + ALNColors.background_gray,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "0 0px",
            }}
            width={"20%"}
          >
            <Typography
              sx={{
                fontFamily: "OpenSans",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "21px",
                padding: "0 0px",
                letterSpacing: "0.02em",
                textAlign: "left",
                color: ALNColors.ligthgray_dark,
                textTransform: "uppercase",
                width: "fit-content",
              }}
            >
              Customer Name
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "0 0px",
            }}
            width={"20%"}
          >
            <Typography
              sx={{
                fontFamily: "OpenSans",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "21px",
                letterSpacing: "0.02em",
                padding: "0 0px",
                textAlign: "left",
                color: ALNColors.ligthgray_dark,
                textTransform: "uppercase",
                width: "fit-content",
              }}
            >
              Mail Type
            </Typography>
          </Box>
          <Box width={"40%"}>
            <Typography
              sx={{
                fontFamily: "OpenSans",
                fontSize: "14px",
                padding: "0 0px",
                fontWeight: "500",
                lineHeight: "21px",
                letterSpacing: "0.02em",
                textAlign: "left",
                color: ALNColors.ligthgray_dark,
                textTransform: "uppercase",
                width: "fit-content",
              }}
            >
              Email
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "0 0px",
            }}
            width={"20%"}
            display={"flex"}
          >
            <Typography
              sx={{
                fontFamily: "OpenSans",
                fontSize: "14px",
                padding: "0 0px",
                fontWeight: "500",
                lineHeight: "21px",
                letterSpacing: "0.02em",
                textAlign: "left",
                color: ALNColors.ligthgray_dark,
                textTransform: "uppercase",
                width: "fit-content",
              }}
            >
              Received
            </Typography>
          </Box>
        </Box>
        <Box>
          {filteredEmailList.map((item) => (
            <Box key={item.id} onClick={() => handleClickItem(item)}>
              <EmailItem
                data={item}
                isSelected={item.id === lastEmailToEdit?.id}
              />
            </Box>
          ))}
          {filteredEmailList.length === 0 && (
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
              alignContent={"center"}
              alignItems={"center"}
              width={"100%"}
              height={"300px"}
            >
              No Emails
            </Box>
          )}
        </Box>
      </Box>
      {/* pagination part */}
      <Box
        sx={{
          height: "37px",
          borderRadius: "16px",
          background: ALNColors.white,
          padding: "10px 50px",
          alignItems: "center",
          border: "1px solid " + ALNColors.background_gray,
        }}
      >
        {page > 1 && (
          <ALNBtn
            variant="text"
            onClick={() => {
              if (page > 1) {
                setPage(page - 1);
              }
            }}
          >
            Prev
          </ALNBtn>
        )}

        {page === 1 && (
          <ALNBtn
            variant="text"
            onClick={() => {}}
            sx={{ color: ALNColors.lightgray }}
          >
            Prev
          </ALNBtn>
        )}

        {filteredEmailList.length === pageSize && (
          <ALNBtn
            variant="text"
            onClick={() => {
              setPage(page + 1);
            }}
          >
            Next
          </ALNBtn>
        )}

        {filteredEmailList.length !== pageSize && (
          <ALNBtn
            variant="text"
            onClick={() => {}}
            sx={{ color: ALNColors.lightgray }}
          >
            Next
          </ALNBtn>
        )}

        <Typography
          fontSize={"15px"}
          sx={{ color: ALNColors.gray }}
          component="span"
          marginRight={1}
        >
          {"  Pg: "}
          {page}
        </Typography>
      </Box>

      {emailToEdit && (
        <EmailEditLayer
          email={emailToEdit}
          onClose={() => {
            setEmailToEdit(null);
            fetchEmails(filter, searchValue, page);
          }}
        />
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {emailStates.map((item, idx) => (
          <MenuItem key={idx}>
            <EmailState
              manualFilter={handleManualFilter}
              activeStatus={filter}
              key={item.id}
              data={item}
            />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

export default EmailList;
