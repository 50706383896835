import { FC } from "react";
import { Typography } from "@mui/material";
import { ALNColors } from "../../utils/color";

interface ALNModalTitleProps {
  title?: string;
}

function ALNModalTitle ({ title }: ALNModalTitleProps) {
  return (
    <Typography id="new-user-modal-title"
      sx={{
        fontFamily: "'OpenSans', sans-serif ",
        fontSize: '24px',
        fontWeight: 550,
        lineHeight: '1.5px',
        color: ALNColors.white,
        letterSpacing: 0.02,
        margin: 0,
      }}
    >
        { title }
    </Typography>
  );
} 

export default ALNModalTitle;