import { Box, Typography } from "@mui/material";
import Divider from '@mui/material/Divider';
import { EmailData } from "../services/models/EmailData";
import { ALNColors } from "../utils/color";

export function DisplayEmail(props: {
  email: EmailData;
  isReply: boolean;
  startCollapsed?: boolean;
}): JSX.Element {
  return (
    <Box 
      sx={{
        border: "1px solid "+ALNColors.gray,
        padding: '5px'
      }}
    >
      {(props.email.body || props.email.htmlBody) && (
        <Box>
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography variant="h6" color={ ALNColors.black }>
              {props.isReply ? "Reply Email" : "Inbound Email"}
            </Typography>
          </Box>
          <Box>
            <Divider />
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="subtitle1"
                sx={{ textDecoration: "underline" }}
              >
                From:
              </Typography>
              <Typography variant="subtitle1">
                {props.email?.from?.address}
              </Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="subtitle1"
                sx={{ textDecoration: "underline" }}
              >
                Date:
              </Typography>
              <Typography variant="subtitle1">
                {props.email?.date?.toLocaleString()}
              </Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="subtitle1"
                sx={{ textDecoration: "underline" }}
              >
                Subject:
              </Typography>
              <Typography variant="subtitle1">
                {props.email?.subject}
              </Typography>
            </Box>
            {!props.email?.htmlBody && (
              <Box sx={{ mb: 2 }}>
                rendering as text
                <Typography
                  variant="subtitle1"
                  sx={{ textDecoration: "underline" }}
                >
                  Body:
                </Typography>
                <Box my={2} />
                <Typography
                  variant="body1"
                  component="pre"
                  sx={{ whiteSpace: "pre-wrap" }}
                >
                  {props.email?.body}
                </Typography>
              </Box>
            )}
            {props.email?.htmlBody && (
              <Box sx={{ mb: 2 }}>
                <Typography
                  variant="subtitle1"
                  sx={{ textDecoration: "underline" }}
                >
                  Body:
                </Typography>
                <Box
                  dangerouslySetInnerHTML={{ __html: props.email.htmlBody }}
                />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}
