import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { EmailStatus } from "../services/models/EmailStatus";
import { ALNColors } from "../utils/color";

interface EmailStateProps {
  data: {
    id: number;
    title: string;
    displaytitle: string;
    icon: string;
    color: string;
    status: EmailStatus;
  };
  manualFilter: (status: EmailStatus) => void;
  activeStatus: string;
}

function EmailState({ data, manualFilter, activeStatus }: EmailStateProps) {
  const [iconSrc, setIconSrc] = useState<string | null>(null);

  useEffect(() => {
    if (data.icon === "") return;
    import(`../assets/images/${data.icon}`)
      .then((module) => setIconSrc(module.default))
      .catch((err) => console.error("Error loading icon:", err));
  }, [data.icon]);

  return (
    <Box>
      {data.title === "All" ? (
        <Box
          style={{
            backgroundColor:
              activeStatus === "All" ? data.color : ALNColors.white,
            cursor: "pointer",
          }}
          sx={{
            display: 'flex',
            alignItems: 'start',
            padding: '8px',
            borderRadius: 0,
            width: 'fit-content',
          }}
          onClick={() => manualFilter("All")}
        >
          <Typography
            style={{ color: activeStatus === "All" ? ALNColors.white : data.color }}
            sx={{
              fontFamily: '"OpenSans", sans-serif',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: 1.5,
              letterSpacing: 0.02,
              textAlign: 'left',
            }}
          >
            All E-Mails
          </Typography>
        </Box>
      ) : (
        <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
              padding: '8px',
              borderRadius: 0,
              width: 'fit-content',
            }}
            style={{
              cursor: "pointer",
              backgroundColor:
                activeStatus === data.status ? data.color : "transparent",
            }}
          onClick={() => manualFilter(data.status)}
        >
          {iconSrc && (
            <Box
              component="img"
              src={iconSrc}
              alt={data.icon}
              sx={{
                marginTop: '5px',
                filter: activeStatus === data.status
                  ? "brightness(0) invert(1)"
                  : "brightness(100%) saturate(100%) invert(0)",
              }}
            />
          )}
          <Typography
            sx={{
              fontFamily: '"OpenSans", sans-serif',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: 1.5,
              letterSpacing: 0.02,
              textAlign: 'left',
            }}
            style={{
              color: activeStatus === data.status ? ALNColors.white : data.color,
            }}
          >
            {data.displaytitle}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default EmailState;
