import { FC } from "react";
import { Box, Link } from "@mui/material"
import { User } from "../../services/models"
import { ALNColors } from "../../utils/color"
import deleteIcon from "../../assets/images/material-symbols_delete.svg"
import editIcon from "../../assets/images/tabler_edit.svg"
import ALNItem from "../../components/form/ALNItem/ALNItem"
import ALNIconBtn from "../../components/form/ALNBtn/ALNIconBtn"

interface UserListItemProps {
  user: User;
  onDelete: (user: User) => void;
  openEditModal: (user: User) => void;
}

export function UserListItem ({ user, onDelete, openEditModal }: UserListItemProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 2,
        borderRadius: 1,
        bgcolor: ALNColors.paper,
        boxShadow: 1,
        cursor: 'pointer',
        '&': { mt: 2 },
      }}
    >
      <Box>
        <ALNItem firstName = { user.firstName } lastName = { user.lastName } />
        <Link href = {`mailto:${user.emailAddress}`} 
          sx = {{
            fontFamily: ['OpenSans', 'sans-serif'],
            fontSize: '0.875rem',
            fontWeight: 'normal',
            lineHeight: '1.3125rem',
            color: ALNColors.gray_dark,
            letterSpacing: '0.0125em',
          }}
          underline = "hover">
          {user.emailAddress}
        </Link>
      </Box>
      <Box>
        <ALNIconBtn obj = { user } onClick = {() => openEditModal(user)} src = { editIcon } alt = "Edit" />
        <ALNIconBtn obj = { user } onClick = {() => onDelete(user)} src = { deleteIcon } alt = "Delete" />
      </Box>
    </Box>
  )
}