import { FC } from "react";
import { EmailAddressRules } from "../../services/models";
import { Box } from "@mui/material";
import deleteIcon from "../../assets/images/material-symbols_delete.svg";
import editIcon from "../../assets/images/tabler_edit.svg";
import { ALNColors } from "../../utils/color";
import ALNItem from "../../components/form/ALNItem/ALNItem"
import ALNIconBtn from "../../components/form/ALNBtn/ALNIconBtn"

export type EmailAddressRuleItemProps = {
  emailAddressRule: EmailAddressRules;
  onDelete: (emailAddressRule: EmailAddressRules) => void;
  openEditModal: (emailAddressRule: EmailAddressRules) => void;
};

export function EmailAddressRulesItem ({ emailAddressRule, onDelete, openEditModal }: EmailAddressRuleItemProps) {
  return (
    <Box 
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        px: '20px',
        py:'12px',
        borderRadius: 1,
        bgcolor: ALNColors.white,
        boxShadow: `0px 1px 1px ${ALNColors.black_opacity}`,
        cursor: 'pointer',
        mb: '16px'
      }}
    >
      <Box>
        <ALNItem firstName = { emailAddressRule.addressWithWildcard } lastName = "" />
      </Box>
      <Box>
        <ALNIconBtn obj = { emailAddressRule } onClick = {() => openEditModal(emailAddressRule)} src = { editIcon } alt = "Edit" />
        <ALNIconBtn obj = { emailAddressRule } onClick = {() => onDelete(emailAddressRule)} src = { deleteIcon } alt = "Delete" />
      </Box>
    </Box>
  );
};
