import { ChangeEvent, useEffect, useState } from "react";
import { EmailAddressRules, RuleType } from "../../services/models";
import { Services } from "../../Services";
import { Box, MenuItem } from "@mui/material";
import { Global } from "../../Global";
import ALNModal from "../../components/modal/ALNModal";
import ALNModalTitle from "../../components/modal/ALNModalTitle";
import ALNModalHeader from "../../components/modal/ALNModalHeader";
import ALNInput from "../../components/form/ALNInput/ALNInput";
import ALNCancelBtn from "../../components/form/ALNBtn/ALNCancelBtn";
import ALNSubmitBtn from "../../components/form/ALNBtn/ALNSubmitBtn";

export type EmailAddressRuleModalProps = {
  open: boolean;
  onClose: () => void;
  emailAddressRule?: EmailAddressRules;
};

export function EmailAddressRulesModal(props: EmailAddressRuleModalProps) {
  const [emailAddressRule, setEmailAddressRule] = useState<EmailAddressRules>(
    {},
  );

  useEffect(() => {
    if (props.emailAddressRule) {
      setEmailAddressRule(props.emailAddressRule);
    } else {
      Services.emailAddressRuleService
        .createEmailAddressRulesData(Global.getJwtToken()!)
        .then((data) => {
          setEmailAddressRule(data);
        });
    }
  }, [props.emailAddressRule]);

  const handleClose = () => {
    setEmailAddressRule({
      addressWithWildcard: "",
      blockedReason: "",
    });
    props.onClose();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEmailAddressRule((prevEmailAddressRule) => ({
      ...prevEmailAddressRule,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    Services.emailAddressRuleService.saveEmailAddressRules(
      Global.getJwtToken()!,
      emailAddressRule,
    );
    handleClose();
  };

  return (
    <ALNModal
      open={props.open}
      onClose={handleClose}
    >
      <ALNModalHeader>
        <ALNModalTitle title = {emailAddressRule.addressWithWildcard !== "" ? "Edit Email Rule" : "New Email Rule"} />
        <ALNCancelBtn onClick = { handleClose } />
      </ALNModalHeader>
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        sx={{
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <ALNInput
          label="Email Address"
          id="addressWithWildcard"
          name="addressWithWildcard"
          value={emailAddressRule.addressWithWildcard}
          onChange={handleChange}
        />
        <ALNInput
          type="select"
          label="Rule Type"
          id="ruleType"
          name="ruleType"
          value={emailAddressRule.ruleType}
          onChange={handleChange}
        >
          <MenuItem value={RuleType.Block}>Block</MenuItem>
          <MenuItem value={RuleType.ForwardAll}>Forward All</MenuItem>
          <MenuItem value={RuleType.ForwardOverLimit}>Forward Over Limit</MenuItem>
        </ALNInput>
        {emailAddressRule.ruleType === RuleType.Block && (
          <ALNInput 
            label="Blocked Reason"
            id="blockedReason"
            name="blockedReason"
            value={emailAddressRule.blockedReason}
            onChange={handleChange}
          />
        )}

        {emailAddressRule.ruleType !== RuleType.Block && (
          <ALNInput 
            label="Forward To"
            id="forwardTo"
            name="forwardTo"
            value={emailAddressRule.forwardTo}
            onChange={handleChange}
          />
        )}

        {emailAddressRule.ruleType === RuleType.ForwardOverLimit && (
          <ALNInput 
            label="Forward Over Limit"
            id="forwardOverLimit"
            name="forwardOverLimit"
            value={emailAddressRule.forwardOverLimit}
            onChange={handleChange}
          />
        )}
        <ALNSubmitBtn />
      </Box>
    </ALNModal>
  );
}
