import React from "react";
import { Email } from "../../../services/models/Email";
import EmailMainPart from "../../../components/EmailMainPart";
import { Box, Typography } from "@mui/material";
import { ALNColors } from "../../../utils/color";

interface IgnoredProps {
  emailData: Email;
  onClose: () => void;
}

function Ignored(props: IgnoredProps) {
  return (
    <Box
      sx={{
        borderRadius: 2,
        boxShadow: `0px 1px 1px 0px ${ALNColors.black_opacity}`,
        backgroundColor: ALNColors.white,
        flex: 1,
      }}
    >
      <EmailMainPart email={props.emailData} onClose={props.onClose} />

      <Box
        sx={{padding: '12px'}}
      >
        <Typography 
          sx={{
            fontFamily: "'OpenSans'",
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "27px",
            letterSpacing: "0.02em",
            textAlign: "left",
            color: ALNColors.gray_darker
          }}
        >Deleted</Typography>
        <Typography 
          sx={{
            fontFamily: "'OpenSans'",
            fontSize: "16px",
            fontWeight: 450,
            lineHeight: "24px",
            letterSpacing: "0.02em",
            textAlign: "left",
            color: ALNColors.gray,
            marginTop: "6px",
          }}
        >
          Deleted By: <span style={{
            fontFamily: "'OpenSans'",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "24px",
            letterSpacing: "0.02em",
            textAlign: "left",
            color: ALNColors.gray_darker
          }}>{props.emailData.modifiedBy}</span>
        </Typography>
      </Box>
    </Box>
  );
}

export default Ignored;
