import React from 'react';
import { Box } from '@mui/material';
interface ALNModalBodyProps {
  children?: React.ReactNode
};
function ALNModalBody ({ children }: ALNModalBodyProps) {
  return(
    <Box
      sx={{
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
      }}
    >
      {children}
    </Box>
  )
}
export default ALNModalBody;