import React from 'react';
import { Typography } from '@mui/material';

interface ALNLabelProps {
  children: React.ReactNode;
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize' | undefined,
  textAlign?: string |undefined,
  fontSize?: number | string ,
  fontFamily? : string | undefined,
  letterSpacing? : string | number,
  color? : string | undefined,
  fontWeight? : number | undefined,
  lineHeight? : number | string,
  backgroundColor? : string | undefined,
  border? : string | undefined
  fontStyle? : string
}

function ALNLabel ({ 
  children, 
  textTransform = 'uppercase', 
  textAlign = 'left', 
  fontSize = '14px',
  fontFamily = "OpenSans",
  letterSpacing = 0.02,
  color,
  lineHeight = '21px',
  fontWeight,
  backgroundColor,
  fontStyle,
  border
}: ALNLabelProps) {
  return (
    <Typography color="inherit"
      sx={{
        fontFamily: {fontFamily},
        fontSize: {fontSize},
        fontWeight: {fontWeight},
        lineHeight: {lineHeight},
        letterSpacing: {letterSpacing},
        textAlign: {textAlign},
        color: {color},
        textTransform: {textTransform},
        border: {border},
        fontStyle: {fontStyle},
        backgroundColor: {backgroundColor}
      }}
    >
      {children}
    </Typography>
  );
};

export default ALNLabel;
