import React from "react";
import { Email } from "../../../services/models/Email";
import { Box, Stack, Typography } from "@mui/material";
import EmailMainPart from "../../../components/EmailMainPart";
import ReactQuill from "react-quill";
import { ALNColors } from "../../../utils/color";
import { Services } from "../../../Services";
import { Global } from "../../../Global";

interface CouldNotRespondLayerProps {
  emailData: Email;
  onClose: () => void;
}

function CouldNotRespondLayer(props: CouldNotRespondLayerProps) {
  const handleSendDraft = () => {
    Services.emailService.modifyDraft(
      Global.getJwtToken()!,
      Global.getGlobal().CurrentMailbox!,
      props.emailData.id,
      props.emailData.reply!.subject!,
      reply,
      true,
    );
    props.onClose();
  };
  const handleSaveDraft = () => {
    Services.emailService.modifyDraft(
      Global.getJwtToken()!,
      Global.getGlobal().CurrentMailbox!,
      props.emailData.id,
      props.emailData.reply!.subject!,
      reply,
      false,
    );
    props.onClose();
  };
  const [reply, setReply] = React.useState(
    props.emailData.reply?.htmlBody || props.emailData.reply?.body || "",
  );

  return (
    <Box
      sx={{
        borderRadius: 2,
        boxShadow: `0px 1px 1px 0px ${ALNColors.black_opacity}`,
        backgroundColor: ALNColors.white,
        flex: 1,
      }}
    >
      <EmailMainPart
        email={props.emailData}
        onClose={props.onClose}
        addedTabTitle="Response"
        addedTab={() => {
          return (
            <Box>
              {Global.getGlobal().user &&
                Global.getGlobal().user?.canRespondToEmails && (
                  <Box paddingBottom={"10px"}>
                    <Typography 
                      sx={{
                        fontFamily: "'OpenSans'",
                        fontSize: "18px",
                        fontWeight: 600,
                        lineHeight: "27px",
                        letterSpacing: "0.02em",
                        textAlign: "left",
                        color: ALNColors.gray_darker
                      }}
                    >
                      Type And Send Response
                    </Typography>

                    <Box height={"400px"}>
                      <ReactQuill
                        theme="snow"
                        placeholder="Type your response here..."
                        value={reply}
                        onChange={setReply}
                        style={{ height: "80%" }}
                        modules={{
                          toolbar: [
                            [{ header: "1" }, { header: "2" }, { font: [] }],
                            [{ size: [] }],
                            [
                              "bold",
                              "italic",
                              "underline",
                              "strike",
                              "blockquote",
                            ],
                            [
                              { list: "ordered" },
                              { list: "bullet" },
                              { indent: "-1" },
                              { indent: "+1" },
                            ],
                            ["link", "image", "video"],
                            ["clean"],
                          ],
                        }}
                      />
                    </Box>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{
                        marginTop: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <Box 
                        sx={{
                          borderRadius: '8px',
                          padding: '12px 32px',
                          bgcolor: ALNColors.primary_btn,
                          fontFamily: "OpenSans",
                          fontSize: '16px',
                          fontWeight: "fontWeightMedium",
                          lineHeight: '24px',
                          letterSpacing: 0.02,
                          textAlign: "left",
                          color: ALNColors.white,
                          cursor: "pointer"
                        }}
                      onClick={handleSendDraft}>
                        Send Draft
                      </Box>
                      <Box 
                        sx={{
                          borderRadius: '8px',
                          padding: '12px 32px',
                          bgcolor: ALNColors.primary_btn,
                          fontFamily: "OpenSans",
                          fontSize: '16px',
                          fontWeight: "fontWeightMedium",
                          lineHeight: '24px',
                          letterSpacing: 0.02,
                          textAlign: "left",
                          color: ALNColors.white,
                          cursor: "pointer"
                        }}
                        onClick={handleSaveDraft}
                      >
                        Save Draft
                      </Box>
                    </Stack>
                  </Box>
                )}
            </Box>
          );
        }}
      />
    </Box>
  );
}

export default CouldNotRespondLayer;
