import {
  Box,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Services } from "../Services";
import { Global } from "../Global";
import { DisplayEmail } from "./DisplayEmail";
import {
  EmailParseRequest,
  EmailData,
  EmailParseResult,
} from "../services/models";

import ALNBtn from "../components/form/ALNBtn/ALNBtn";
import ALNTextField from "../components/form/ALNInput/ALNTextField";
import ALNToggleThreeBtn from "../components/form/ALNBtn/ALNToggleThreeBtn";
import { ALNColors } from "../utils/color";

interface Product {
  id: number;
  name: string;
  quantity: number;
}

function QuoteTool() {
  const [products, setProducts] = useState<Product[]>([
    {
      id: Date.now(),
      name: "",
      quantity: 0,
    },
  ]);

  const [results, setResults] = useState<string | undefined>();
  const [fullResults, setFullResults] = useState<string | undefined>();
  const [resultType, setResultType] = useState("email");
  const [emailReply, setEmailReply] = useState<EmailData | undefined>();

  const [emailParseResults, setEmailParseResults] = useState<
    EmailParseResult | undefined
  >();

  if (
    products.length === 0 ||
    products.every((product) => product.name !== "" && product.quantity !== 0)
  ) {
    setProducts([
      ...products,
      {
        id: Date.now(),
        name: "",
        quantity: 0,
      },
    ]);
  }

  const handleDeleteProduct = (id: number) => {
    setProducts(products.filter((product) => product.id !== id));
  };

  const handleSubmitFull = () => {
    setEmailParseResults(undefined);
    setResults(undefined);
    setFullResults(undefined);
    setEmailReply(undefined);
    let parseRequest: EmailParseRequest = {
      mailboxName: Global.getGlobal().CurrentMailbox!,
      email: {
        subject: "Quote Request",
        body:
          "Please provide quote for following \n\n" +
          products
            .filter((p) => p.quantity !== 0)
            .map((p) => p.name + " qty: " + p.quantity)
            .join("\n"),
        from: { address: "test@test.com", name: "Joe" },
      },
    };

    Services.testService
      .parseEmail(Global.getJwtToken()!, parseRequest)
      .then((res) => {
        setFullResults(JSON.stringify(res, null, 2));
        setResults(JSON.stringify(res.productInfo));
        setEmailReply(res.email?.reply);
        setEmailParseResults(res);
      });
  };

  const handleClear = () => {
    setProducts([]);
    setResults(undefined);
  };

  function addRandom(): void {
    const randomQuantity = Math.floor(Math.random() * 1000) + 1;
    Services.testService
      .getRandomProductNames(
        Global.getJwtToken()!,
        Global.getGlobal().CurrentMailbox!,
      )
      .then((res) => {
        let p = [
          ...products,
          {
            id: Date.now(),
            name: res[Math.floor(Math.random() * res.length)],
            quantity: randomQuantity,
          },
        ];

        p.sort((a, b) => (a.quantity === 0 ? 1 : b.quantity === 0 ? -1 : 0));
        setProducts(p);
      });
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "25px 50px",
        background: "radial-gradient(83.46% 103.4% at 30.48% 32.62%, rgba(77, 78, 141, 0.15) 0%, rgba(237, 237, 244, 0.15) 100%)"
      }}
    >
      <Typography sx={{fontSize: "10px", lineHeight: "12px", fontWeight: 500, width: "fit-content"}}>
        Quote Tool
      </Typography>
      <Typography sx={{fontSize: "40px", lineHeight: "50px", fontWeight: 400, width: "fit-content"}}>
        Quote Tool
      </Typography>
      <Box
        display={"flex"}
        flexDirection={"row"}
        sx={{
          background: ALNColors.white,
          padding: "25px 25px", 
          borderRadius : "16px",
          border: "1px solid "+ALNColors.background_gray,
          height: 'calc(100vh - 220px)',
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          paddingRight={"10px"}
          borderRight={`1px solid ${ALNColors.black}`}
          sx={{ width: "50%" }}
        >
          <Box>
            {products.map((product, index) => (
              <Box key={product.id} marginTop="10px" display={"flex"}>
                <ALNTextField
                  label="Product Name"
                  variant="filled"
                  sx={{ width: "200px", paddingRight: "10px" }}
                  value={product.name}
                  onChange={(currentValue) => {
                    const newProducts = [...products];
                    newProducts[index].name = currentValue;
                    setProducts(newProducts);
                  }}
                />
                <ALNTextField
                  label="Quantity"
                  variant="filled"
                  type="number"
                  sx={{ width: "200px", paddingRight: "10px" }}
                  value={product.quantity}
                  onChange={(currentValue) => {
                    const newProducts = [...products];
                    newProducts[index].quantity = currentValue
                      ? Number(currentValue)
                      : 0;
                    setProducts(newProducts);
                  }}
                />
                <ALNBtn variant="text" onClick = { () => handleDeleteProduct(product.id) }>Delete</ALNBtn>
              </Box>
            ))}
          </Box>
          <Box paddingLeft={"10px"} display="flex" gap="10px" marginTop="20px">
            <ALNBtn variant="contained" onClick = { handleSubmitFull } >Submit</ALNBtn>
            <ALNBtn onClick = { handleClear } >Clear</ALNBtn>
            <ALNBtn onClick = { addRandom } >Add Random</ALNBtn>
          </Box>
        </Box>

        {results && (
          <Box 
            sx={{ 
              width: "50%",
            }} 
            paddingLeft={"10px"}>
            <Typography variant="h5">Results:</Typography>
            <ALNToggleThreeBtn
              value={resultType}
              firstToggle="email"
              secondToggle="data"
              thirdToggle="full"
              aria_label="result type"
              aria_label_first="email"
              aria_label_second="data"
              aria_label_third="full"
              onChange={ (newValue) => {
                setResultType(newValue); 
              }}
            />
            <Box
              component="pre"
              sx={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                maxHeight: "87%",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {resultType === "email" && emailReply && (
                <Box display={"flex"} flexDirection={"column"}>
                  {emailParseResults && (
                    <Box>
                      <Typography>
                        Time Taken:{" "}
                        {emailParseResults.email?.timeTakenInSeconds?.toFixed(3)}{" "}
                        seconds
                      </Typography>
                      <Typography>
                        Order Total: ${" "}
                        {emailParseResults.email?.totalValue?.toFixed(2)}
                      </Typography>
                      <Typography>
                        Action Proposed: {emailParseResults.email?.emailStatus}
                      </Typography>
                      {emailParseResults.email?.cannotReplyReason &&
                        emailParseResults.email?.cannotReplyReason?.length >
                          0 && (
                          <Box>
                            <Typography>Issues:</Typography>

                            <Typography
                              sx={{
                                color: ALNColors.red,
                                border: "1px solid "+ALNColors.warn,
                                backgroundColor: ALNColors.pink_light,
                                textAlign: "left",
                              }}
                            >
                              {emailParseResults.email?.cannotReplyReason?.map(
                                (reason) => (
                                  <Box key={reason} component="span" display="block">
                                    <Typography variant="body2">- {reason}</Typography>
                                  </Box>
                                ),
                              )}
                            </Typography>
                          </Box>
                        )}
                    </Box>
                  )}
                  <Box sx={{ mb: 2 }}></Box>

                  {emailReply && (
                    <DisplayEmail email={emailReply} isReply={true} />
                  )}
                </Box>
              )}
              {resultType === "data" && results}
              {resultType === "full" && fullResults}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default QuoteTool;
