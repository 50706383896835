import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import ALNBtn from "../components/form/ALNBtn/ALNBtn";
import { Company } from "../services/models";
import { CompanyListItem } from "./company/CompanyListItem";
import { CompanyModal } from "./company/CompanyModal";
import { Global } from "../Global";
import { Services } from "../Services";
import { ALNColors } from "../utils/color";

export function CompaniesList(): JSX.Element {
  const [companyModalOpen, setCompanyModalOpen] = useState<boolean>(false);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [editCompany, setEditCompany] = useState<Company>({
    companyName: "",
    companyLogoURL: "",
    maxEmailsPerUserPerDay: 0,
  });

  const token = Global.getJwtToken();

  useEffect(() => {
    refreshCompanies();
  }, [token]);

  const handleNewCompanyClick = () => {
    setCompanyModalOpen(true);
  };

  const handleCompanyModalClose = () => {
    setCompanyModalOpen(false);
    Services.companyService
      .createCompanyData(Global.getJwtToken()!)
      .then((data) => {
        setEditCompany(data);
      });
    setTimeout(() => {
      Global.resetCompanyList();
    }, 200);
  };

  const handleDeleteCompany = (company: Company) => {
    Services.companyService
      .deleteCompany(Global.getJwtToken()!, company.companyName!)
      .then(() => {
        refreshCompanies();
      });
  };

  const handleEditCompany = (company: Company) => {
    setEditCompany((prevCompany) => ({ ...prevCompany, ...company }));
    setCompanyModalOpen(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "25px 50px",
        background: "radial-gradient(83.46% 103.4% at 30.48% 32.62%, rgba(77, 78, 141, 0.15) 0%, rgba(237, 237, 244, 0.15) 100%)"
      }}
    >
      <Typography sx={{fontSize: "10px", lineHeight: "12px", fontWeight: 500, width: "fit-content"}}>
        Companies
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '40px',
          marginBottom: '20px',
        }}
      >
        <Typography sx={{fontSize: "40px", lineHeight: "50px", fontWeight: 400, width: "fit-content"}}>
          Companies
        </Typography>
        <ALNBtn 
          variant="contained"
          sx={{ bgcolor: ALNColors.email_manual, height: 45 }}
          onClick={handleNewCompanyClick}
          >
          <Typography
            sx={{
              fontSize: '16px !important',
              fontWeight: 600,
              fontFamily: "'Open Sans', sans-serif",
              lineHeight: 1.5,
              color: ALNColors.white,
              letterSpacing: '2%',
              textTransform: 'capitalize',
            }}
          >
            New Company
          </Typography>
        </ALNBtn>
      </Box>
      <Box
        sx={{
          background: ALNColors.white,
          padding: "16px", 
          borderRadius : "16px",
          border: "1px solid "+ALNColors.background_gray,
          flexGrow: 1,
          height: 'calc(100vh - 220px)',
        }}
      >
        <Box
          sx={{
            maxHeight: '100%',
            overflowY: 'scroll'
          }}
        >
          {companies.map((company: Company) => (
            <CompanyListItem
              onDelete={handleDeleteCompany}
              openEditModal={handleEditCompany}
              key={company.id}
              company={company}
            />
          ))}
        </Box>
      </Box>
      <CompanyModal
        company={editCompany}
        open={companyModalOpen}
        onClose={handleCompanyModalClose}
      />
    </Box>
  );

  function refreshCompanies() {
    Services.companyService
      .getAllCompanies(Global.getJwtToken()!)
      .then((data) => {
        setCompanies(data);
      });
  }
}
